import { isRoleValid } from '@knapsack/core';
import type { Role } from '@knapsack/types';

// This will only work for Super Admins

const key = 'ks-user-role-overide';

export function getUserRoleOverride(): Role | null {
  const role = window.sessionStorage.getItem(key);
  if (isRoleValid(role)) return role;
}

export function setUserRoleOverride(role: Role): void {
  if (!isRoleValid(role)) return;
  window.sessionStorage.setItem(key, role);
}

export function removeUserRoleOverride(): void {
  window.sessionStorage.removeItem(key);
}
