/**
 *  Copyright (C) 2018 Basalt
    This file is part of Knapsack.
    Knapsack is free software; you can redistribute it and/or modify it
    under the terms of the GNU General Public License as published by the Free
    Software Foundation; either version 2 of the License, or (at your option)
    any later version.

    Knapsack is distributed in the hope that it will be useful, but WITHOUT
    ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or
    FITNESS FOR A PARTICULAR PURPOSE.  See the GNU General Public License for
    more details.

    You should have received a copy of the GNU General Public License along
    with Knapsack; if not, see <https://www.gnu.org/licenses>.
 */
import type { KsClientPlugin } from '@knapsack/app/types';
import { knapsackGlobal } from '../../global';

/**
 * Client Plugins
 * Plugins are internal alpha only for now, continued work on them with probably frequent API changes will happen until we feel confident in external use in the future.
 */
export class PluginStore {
  plugins: Record<string, KsClientPlugin>;

  constructor() {
    this.plugins = {};
  }

  /**
   * Register Knapsack plugin
   */
  register(plugin: KsClientPlugin): void {
    if (!plugin?.id) {
      console.warn('An empty plugin just attempted registration', plugin);
      return;
    }
    if (plugin.id in this.plugins) {
      throw new Error(
        `Plugin with id "${plugin.id}" has already been registered.`,
      );
    }
    this.plugins[plugin.id] = plugin;
  }

  getPlugins(): KsClientPlugin[] {
    return Object.values(this.plugins);
  }

  getPlugin(pluginId: string): KsClientPlugin {
    return this.plugins[pluginId];
  }

  resetPlugins(): void {
    this.plugins = {};
  }
}

function getPluginStore(): PluginStore {
  if (!knapsackGlobal.pluginStore) {
    knapsackGlobal.pluginStore = new PluginStore();
  }
  return knapsackGlobal.pluginStore;
}

export const plugins: PluginStore = getPluginStore();
