import { tokens } from '@knapsack/toby';
import {
  getNavItemByPathName,
  determineTopNav,
  determineTopNavItem,
} from '@/utils/nav';
import { Draft } from 'immer';
import { UiCtx, WinWidthNames } from './types';

export function getWinWidthName(width: number): WinWidthNames {
  switch (true) {
    case width > parseInt(tokens.breakpoint.large, 10):
      return 'xlarge';
    case width > parseInt(tokens.breakpoint.medium, 10):
      return 'large';
    case width > parseInt(tokens.breakpoint.small, 10):
      return 'medium';
    case width > parseInt(tokens.breakpoint.xsmall, 10):
      return 'small';
    default:
      return 'xsmall';
  }
}

export function handleNavChanges(ctx: Draft<UiCtx>) {
  const { pathname } = window.location;
  // No source (ie immediate page load), bail
  if (!ctx.nav.source.length) {
    return;
  }
  const activeSubNavId = getNavItemByPathName({
    source: ctx.nav.source,
    pathname,
  });
  ctx.nav.activeSubNavId = activeSubNavId?.id;

  const topNav = determineTopNav(ctx.nav.source);
  ctx.nav.top = topNav;

  const topNavItem = determineTopNavItem({
    source: ctx.nav.source,
    id: activeSubNavId?.id,
  });
  ctx.nav.activeTopNavItem = topNavItem;
}
