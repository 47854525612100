import { send, StateNodeConfig } from 'xstate';
import { assign } from '@xstate/immer';
import {
  initLaunchDarkly,
  identifyLdUserWithSite,
  identifyLdAnonUserWithSite,
  addProfileInfoToCurrentLdUser,
  LaunchDarklyFeatureFlags,
  identifyLdUserWithoutSite,
  isFeatureFlagKey,
} from '@/utils/launch-darkly-feature-flags';
import { removeAnalyticsUser, updateAnalyticsUser } from '@/utils/analytics';
import { isBrowser } from '@knapsack/utils';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  APP_SUB_MACHINE_IDS,
  SharedEvents,
  removePlansFlags,
} from '../app.xstate-utils';
import { isCypress, isUnitTesting } from '../../../../../utils/constants';

/**
 * A special state node that guarantees context for BOTH site and user.
 */
export const usersAndSiteStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['usersAndSite'],
  AppEvents
> = {
  id: 'usersAndSite',
  initial: 'unknown',
  strict: true,
  invoke: [
    {
      id: 'featureFlags',
      src: () => (sendEvent) => {
        if (!isBrowser) return;
        initLaunchDarkly()
          .then((ldClient) => {
            sendEvent({
              type: 'featureFlags.changed',
              flags: ldClient.allFlags(),
            });

            // Now listen for any changes to flags and inform Xstate
            ldClient.on(
              'change',
              (flagsChanges: {
                [FlagName in keyof Partial<LaunchDarklyFeatureFlags>]?: {
                  current: LaunchDarklyFeatureFlags[FlagName];
                  previous: LaunchDarklyFeatureFlags[FlagName];
                };
              }) => {
                const flags = Object.entries(flagsChanges).reduce(
                  (acc, [flag, value]) => {
                    if (!isFeatureFlagKey(flag)) {
                      console.error('Trying to use flag that does not exist.');
                      return acc;
                    }

                    const { current, previous } = value;

                    current !== previous &&
                      console.debug(
                        `Feature Flag "${flag}" change from "${previous}" => "${current}"`,
                      );

                    // So what the hell is all this below? Our Launch Darkly flags
                    // are all of type `boolean` except for `gitLabMergeDelay`.
                    // This means Typescript needs us to be **absolutely** sure
                    // we're assigning the proper exact type of value to the only
                    // key that is not a boolean value.

                    switch (flag) {
                      case 'gitLabMergeDelay':
                        if (typeof current === 'number') acc[flag] = current;
                        break;
                      case 'appBannerMsg':
                        if (typeof current === 'string') acc[flag] = current;
                        break;
                      // Everything else
                      default:
                        if (typeof current === 'boolean') acc[flag] = current;
                        break;
                    }

                    return acc;
                  },
                  {} as Partial<LaunchDarklyFeatureFlags>,
                );

                sendEvent({
                  type: 'featureFlags.changed',
                  flags,
                });
              },
            );
          })
          .catch((error) => {
            const msg = `Feature Flag init failed: ${error.message}`;
            console.error(msg, error);
          });
      },
    },
  ],
  on: {
    'featureFlags.changed': {
      actions: [
        assign((ctx, { flags }) => {
          ctx.featureFlags = {
            ...ctx.featureFlags,
            ...removePlansFlags(flags),
          };
        }),
      ],
    },
  },
  states: {
    unknown: {
      always: [
        {
          in: '#app.site.loaded',
          target: 'siteLoaded',
        },
      ],
      initial: 'noUser',
      states: {
        noUser: {
          always: [
            {
              in: '#app.user.loggedIn',
              target: 'withUser',
            },
          ],
        },
        // We have routes where a user can exist, but no site! eg / & /sign-up
        withUser: {
          entry: (ctx) => {
            // LaunchDarkly doesn't like if we fire multiple "identify" functions in quick succesion - we'll get back wrong flags
            // this makes sure to only identify this user **without a site** when we are not in `/site`
            if (window.location.pathname.startsWith('/site')) return;
            const { userId, email, isSuperAdmin } = ctx.user;

            identifyLdUserWithoutSite({ userId, email, isSuperAdmin });
          },
          always: [
            {
              in: '#app.user.loggedOut',
              target: 'noUser',
            },
          ],
        },
      },
    },
    siteLoaded: {
      initial: 'unknownUser',
      states: {
        unknownUser: {
          always: [
            {
              in: '#app.user.loggedIn',
              target: 'loggedInUser',
            },
            {
              in: '#app.user.loggedOut',
              target: 'anonUser',
            },
          ],
        },
        anonUser: {
          entry: [
            {
              type: 'Tell LaunchDarkly about anon User',
              exec: (ctx: AppContext) => {
                const { siteId } = ctx.site.meta;
                identifyLdAnonUserWithSite({
                  siteId,
                });
              },
            },
          ],
          always: [
            {
              in: '#app.user.loggedIn',
              target: 'loggedInUser',
            },
          ],
        },
        loggedInUser: {
          entry: [
            {
              type: 'Tell analytics about User & Site',
              exec: ({ user, site }: AppContext) => {
                if (isCypress() || isUnitTesting) return;
                const { responsibilityId, dateCreated } = user.info ?? {};
                const { userId, email, isSuperAdmin } = user;
                const siteId = site?.meta.siteId;
                if (!siteId) {
                  console.error(
                    `No siteId was available when there should be one while trying to set analytics user!`,
                    { user, site },
                  );
                  return;
                }
                const role = user.getSiteRole(siteId);
                const internalEmailDomains: string[] = [
                  '@knapsack.cloud',
                  '@atomle.com',
                ];
                const isInternalUser =
                  isSuperAdmin ||
                  internalEmailDomains.some((domain) => email.endsWith(domain));
                updateAnalyticsUser({
                  userId,
                  role,
                  isInternalUser,
                  responsibility: responsibilityId,
                  dateCreated,
                });
              },
            },
            {
              type: 'Tell LaunchDarkly about User & Site',
              exec: (ctx: AppContext) => {
                const { userId, email, isSuperAdmin, info, getSiteRole } =
                  ctx.user;
                const { siteId } = ctx.site.meta;
                const roleForSite = getSiteRole(siteId);
                identifyLdUserWithSite({
                  email,
                  isSuperAdmin,
                  userId,
                  siteId,
                  profilePic: info?.profilePic,
                  roleForSite,
                });
              },
            },
            send(
              (ctx): SharedEvents => {
                const { userId, email, info, getSiteRole } = ctx.user;
                const { siteId } = ctx.site.meta;
                const roleForSite = getSiteRole(siteId);
                return {
                  type: 'userAndSite.haveBoth',
                  userId,
                  roleForSite,
                  siteId,
                };
              },
              {
                to: APP_SUB_MACHINE_IDS.appClientData,
              },
            ),
          ],
          exit: [
            {
              type: 'remove analytics user',
              exec: () => removeAnalyticsUser(),
            },
            {
              type: 'Tell LaunchDarkly about anon User',
              exec: (ctx: AppContext) => {
                const { siteId } = ctx.site.meta;
                identifyLdAnonUserWithSite({
                  siteId,
                });
              },
            },
            send(
              (ctx): SharedEvents => {
                return {
                  type: 'user.unload',
                };
              },
              {
                to: APP_SUB_MACHINE_IDS.appClientData,
              },
            ),
          ],
          always: [
            {
              in: '#app.user.loggedOut',
              target: 'anonUser',
            },
          ],
          initial: 'someInfo',
          states: {
            someInfo: {
              entry: [
                {
                  type: 'Tell LaunchDarkly about User Profile',
                  exec: (ctx: AppContext) => {
                    const { info } = ctx.user;
                    if (!info) return;
                    const { profilePic, firstName, lastName } = info;
                    addProfileInfoToCurrentLdUser({
                      profilePic,
                      firstName,
                      lastName,
                    });
                  },
                },
              ],
              always: [
                {
                  in: '#app.user.loggedIn.loaded',
                  target: 'allInfo',
                },
              ],
            },
            allInfo: {},
          },
        },
      },
    },
  },
};
