import { Draft } from 'immer';
import { makeShortId as makeId, makeShortId, slugify } from '@knapsack/utils';
import {
  KnapsackPattern,
  KsAppClientDataNoMeta,
  KnapsackPatternTemplate,
  KsTemplateSpec,
  KsStatusSet,
  DataDemo,
  TemplateDemo,
  RendererId,
} from '@knapsack/types';
import { trackEvent } from '@/utils/analytics';
import {
  ActionMap,
  ExtractActionsFromActionMap,
  getActionHandlerFromMap,
  hasActionOnMap,
} from '@/core/xstate/xstate.utils';
import {
  Action,
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
  DELETE_PATTERN,
  DeletePatternAction,
  AddPatternAction,
  ADD_PATTERN,
} from './shared.xstate';
import {
  mergeDeepInImmer,
  removeBlock,
  removeDemoFromSlots,
} from './utils/utils.xstate';

export type PatternsState = KsAppClientDataNoMeta['patternsState'];

export const patternsInitialState: PatternsState = {
  patterns: {},
  renderers: {},
  statusSets: [],
};
const REMOVE_TEMPLATE_DEMO = 'knapsack/patterns/REMOVE_TEMPLATE_DEMO';
const ADD_TEMPLATE = 'knapsack/patterns/ADD_TEMPLATE';
const UPDATE_PATTERN = 'knapsack/patterns/UPDATE_PATTERN';
const UPDATE_TEMPLATE_INFO = 'knapsack/patterns/UPDATE_TEMPLATE_INFO';
const DELETE_TEMPLATE = 'knapsack/patterns/DELETE_TEMPLATE';
export interface DeleteTemplateAction extends Action {
  type: typeof DELETE_TEMPLATE;
  payload: {
    patternId: string;
    templateId: string;
  };
}

/** Delete a pattern's template */
export function deleteTemplate(
  payload: DeleteTemplateAction['payload'],
): DeleteTemplateAction {
  return {
    type: DELETE_TEMPLATE,
    payload,
  };
}

const SET_TEMPLATE_ASSET_SETS = 'knapsack/patterns/SET_TEMPLATE_ASSET_SETS';
interface SetTemplateAssetSets extends Action {
  type: typeof SET_TEMPLATE_ASSET_SETS;
  payload: {
    patternId: string;
    templateId: string;
    /** Setting to `null` means it will inherit (i.e. use globalAssetSetIds), setting to `[]` means no asset sets will be available */
    assetSetIds: null | string[];
  };
}
export function setTemplateAssetSets(
  payload: SetTemplateAssetSets['payload'],
): SetTemplateAssetSets {
  return {
    type: SET_TEMPLATE_ASSET_SETS,
    payload,
  };
}

const SET_DEMO_ASSET_SET = 'knapsack/patterns/SET_DEMO_ASSET_SET';
interface SetDemoAssetSet extends Action {
  type: typeof SET_DEMO_ASSET_SET;
  payload: {
    demoId: string;
    /** Setting to `null` means it will inherit (i.e. use Template's `assetSetIds` or `globalAssetSetIds`) */
    assetSetId: null | string;
  };
}
export function setDemoAssetSet(
  payload: SetDemoAssetSet['payload'],
): SetDemoAssetSet {
  return {
    type: SET_DEMO_ASSET_SET,
    payload,
  };
}
export const TOGGLE_INFER_SPEC = 'patterns.toggleInferSpec';

const UPDATE_SPEC = 'knapsack/patterns/UPDATE_SPEC';
export interface UpdateSpecAction extends Action {
  type: typeof UPDATE_SPEC;
  payload: {
    patternId: string;
    templateId: string;
    spec: KsTemplateSpec;
  };
}
export function updateSpec(
  payload: UpdateSpecAction['payload'],
): UpdateSpecAction {
  return {
    type: UPDATE_SPEC,
    payload,
  };
}

const DUPLICATE_DEMO = 'knapsack/patterns/DUPLICATE_DEMO';
export interface DuplicateDemoAction extends Action {
  type: typeof DUPLICATE_DEMO;
  payload: {
    patternId: string;
    templateId: string;
    demoId: string;
    newDemoId?: string;
  };
}
export function duplicateDemo(
  payload: DuplicateDemoAction['payload'],
): DuplicateDemoAction {
  return {
    type: DUPLICATE_DEMO,
    payload,
  };
}

const ADD_PATTERN_SUB_PAGE = 'knapsack/patterns/ADD_PATTERN_SUB_PAGE';
export interface AddPatternSubPage extends Action {
  type: typeof ADD_PATTERN_SUB_PAGE;
  payload: {
    patternId: string;
    title: string;
    id?: string;
  };
}
export function addPatternSubPage(
  payload: AddPatternSubPage['payload'],
): AddPatternSubPage {
  return {
    type: ADD_PATTERN_SUB_PAGE,
    payload,
  };
}

const DELETE_PATTERN_SUB_PAGE = 'knapsack/patterns/DELETE_PATTERN_SUB_PAGE';
export interface DeletePatternSubPage extends Action {
  type: typeof DELETE_PATTERN_SUB_PAGE;
  payload: {
    patternId: string;
    subPageId: string;
  };
}
export function deletePatternSubPage(
  payload: DeletePatternSubPage['payload'],
): DeletePatternSubPage {
  return {
    type: DELETE_PATTERN_SUB_PAGE,
    payload,
  };
}

const REORDER_PATTERN_TABS = 'knapsack/patterns/REORDER_PATTERN_TABS';
export interface ReorderPatternTabs extends Action {
  type: typeof REORDER_PATTERN_TABS;
  payload: {
    patternId: string;
    tabIds: string[];
  };
}
export function reorderPatternTabs(
  payload: ReorderPatternTabs['payload'],
): ReorderPatternTabs {
  return {
    type: REORDER_PATTERN_TABS,
    payload,
  };
}

export interface AddTemplateAction extends Action {
  type: typeof ADD_TEMPLATE;
  payload: {
    patternId: string;
    templateId?: string;
    templateLanguageId: RendererId;
    path: string;
    alias?: string;
    title?: string;
    assetSetIds?: string[];
    inferSpec: boolean;
  };
}

export interface RemoveTemplateDemoAction extends Action {
  type: typeof REMOVE_TEMPLATE_DEMO;
  payload: {
    patternId: string;
    templateId: string;
    demoId: string;
  };
}

/**
 * @todo add option to delete file (ifTemplateDemo)
 */
export function removeTemplateDemo({
  patternId,
  templateId,
  demoId,
}: {
  patternId: string;
  templateId: string;
  demoId: string;
}): RemoveTemplateDemoAction {
  return {
    type: REMOVE_TEMPLATE_DEMO,
    payload: {
      patternId,
      templateId,
      demoId,
    },
  };
}

export interface UpdatePatternAction extends Action {
  type: typeof UPDATE_PATTERN;
  payload: KnapsackPattern;
}

export function updatePattern(pattern: KnapsackPattern): UpdatePatternAction {
  return {
    type: UPDATE_PATTERN,
    payload: pattern,
  };
}

export interface UpdateTemplateInfoAction extends Action {
  type: typeof UPDATE_TEMPLATE_INFO;
  payload: {
    patternId: string;
    templateId: string;
    template: Partial<KnapsackPatternTemplate>;
  };
}

/**
 * Update basic Template Info by doing a shallow merge of `template`
 */
export function updateTemplateInfo({
  patternId,
  templateId,
  template,
}: {
  patternId: string;
  templateId: string;
  template: Partial<KnapsackPatternTemplate>;
}): UpdateTemplateInfoAction {
  return {
    type: UPDATE_TEMPLATE_INFO,
    payload: {
      patternId,
      templateId,
      template,
    },
  };
}

const SET_PATTERN_STATUS = 'knapsack/patterns/SET_PATTERN_STATUS';
export interface SetPatternStatusAction extends Action {
  type: typeof SET_PATTERN_STATUS;
  payload: {
    patternId: string;
    statusSetId: string;
    statusId: string;
  };
}
export function setPatternStatus(
  payload: SetPatternStatusAction['payload'],
): SetPatternStatusAction {
  return {
    type: SET_PATTERN_STATUS,
    payload,
  };
}

const SET_STATUS_SET = 'knapsack/patterns/SET_STATUS_SET';
export interface SetStatusSet extends Action {
  type: typeof SET_STATUS_SET;
  payload: KsStatusSet;
}
/**
 * Add or Update a Status Set for all patterns
 * @see {KsStatusSet}
 */
export function setStatusSet(payload: SetStatusSet['payload']): SetStatusSet {
  return {
    type: SET_STATUS_SET,
    payload,
  };
}

const DELETE_STATUS_SET = 'knapsack/patterns/DELETE_STATUS_SET';
export interface DeleteStatusSet extends Action {
  type: typeof DELETE_STATUS_SET;
  payload: {
    statusSetId: string;
  };
}
/**
 * Add or Update a Status Set for all patterns
 * @see {KsStatusSet}
 */
export function deleteStatusSet(
  payload: DeleteStatusSet['payload'],
): DeleteStatusSet {
  return {
    type: DELETE_STATUS_SET,
    payload,
  };
}

/**
 * @see {@link ActionMap} for details on how this works
 * basically, the keys are the `action.type` when we send in events
 * and the values are the functions that will be called when that event is sent
 * 1st param is an Immer Draft of App Client Data
 * 2nd param is the action sent in - define the type of this param.
 */
export const patternsActionMap = {
  'patterns.toggleHideFromComponentBlocks': (
    data,
    { patternId }: { patternId: string },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
    pattern.hideFromComponentBlocks = !pattern.hideFromComponentBlocks;
  },
  'patterns.toggleHideFromOverviewBlocks': (
    data,
    { patternId }: { patternId: string },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
    pattern.hideFromOverviewBlocks = !pattern.hideFromOverviewBlocks;
  },
  'patterns.updateDescription': (
    data,
    { patternId, description }: { patternId: string; description: string },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
    if (pattern.description === description) return;
    pattern.description = description;
  },
  'proto.setEnabledRenderers': (
    data,
    { enabledRenderers }: { enabledRenderers: RendererId[] },
  ) => {
    const { patterns, renderers } = data.patternsState;
    if (enabledRenderers.length === 0) {
      // sending in empty array means we want to disable prototyping
      const patternId = data.db.settings.prototyping?.patternId;
      if (patternId) {
        delete data.patternsState.patterns[patternId];
        // data doctor will clean up demos
      }
      delete data.db.settings.prototyping;
      return;
    }
    const patternId =
      // pre-existing patternId
      data.db.settings.prototyping?.patternId ||
      // create new patternId
      (() => {
        const patternIds = Object.keys(patterns);
        if (!patternIds.includes('prototypes')) return 'prototypes';
        if (!patternIds.includes('ks-prototypes')) return 'ks-prototypes';
        return `prototypes-${makeShortId()}`;
      })();
    data.db.settings.prototyping = {
      patternId,
    };
    if (!patterns[patternId]) {
      // setup new prototyping pattern if it doesn't exist
      patterns[patternId] = {
        id: patternId,
        title: 'Prototyping',
        hideFromComponentBlocks: true,
        hideFromOverviewBlocks: true,
        templates: [],
      };
    }
    enabledRenderers.forEach((rendererId) => {
      const rendererMeta = renderers[rendererId]?.meta;
      if (!rendererMeta) return;
      if (
        patterns[patternId].templates.find(
          (t) => t.templateLanguageId === rendererId,
        )
      ) {
        // already exists
        return;
      }
      const { title, prototypingTemplate } = rendererMeta;
      patterns[patternId].templates.push({
        id: rendererId,
        templateLanguageId: rendererId,
        title,
        path: prototypingTemplate.path,
        alias: prototypingTemplate.alias,
        spec: prototypingTemplate.spec,
        demoIds: [],
        blockIds: [],
      });
    });
    const finalEnabledTemplates = patterns[patternId].templates.filter((t) =>
      enabledRenderers.includes(t.templateLanguageId),
    );
    if (finalEnabledTemplates.length !== patterns[patternId].templates.length) {
      // this means we removed a template
      // only setting this if we removed a template so we keep diffs down
      patterns[patternId].templates = finalEnabledTemplates;
    }
  },
} satisfies ActionMap;

export type PatternsActions =
  | ExtractActionsFromActionMap<typeof patternsActionMap>
  | AddPatternAction
  | DeletePatternAction
  | AddTemplateAction
  | UpdatePatternAction
  | UpdateTemplateInfoAction
  | AddPatternSubPage
  | DeletePatternSubPage
  | ReorderPatternTabs
  | RemoveTemplateDemoAction
  | DuplicateDemoAction
  | DeleteTemplateAction
  | UpdateSpecAction
  | SetDemoAssetSet
  | SetTemplateAssetSets
  | SetPatternStatusAction
  | SetStatusSet
  | SetAppClientData
  | ResetAppClientData
  | DeleteStatusSet
  | {
      type: typeof TOGGLE_INFER_SPEC;
      patternId: string;
      templateId: string;
      isInferred: boolean;
    }
  | {
      type: 'patterns.updateTitle';
      patternId: string;
      title: string;
    }
  | {
      type: 'patterns.subPage.updateTitle';
      patternId: string;
      subPageId: string;
      title: string;
    }
  | {
      type: 'add.statusSet';
      statusSet: KsStatusSet;
    }
  | {
      type: 'update.statusSet';
      statusSet: KsStatusSet;
    }
  | {
      type: 'delete.statusSet';
      statusSetId: string;
    }
  | {
      type: 'duplicate.statusSet';
      statusSet: KsStatusSet;
    }
  | {
      type: 'statusSets.update';
      statusSets: KsStatusSet[];
    };

export default function reducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: PatternsActions,
): PatternsState {
  // providing some backwards compatibility vars for when this was Redux style: return new state shape for this state property
  // each `case` often used Immer's produce for that one `case`, now Immer is called higher up
  const state = data.patternsState;
  const draft = data.patternsState;

  if (hasActionOnMap(action, patternsActionMap)) {
    getActionHandlerFromMap(action, patternsActionMap)(data, action);
    return;
  }

  switch (action.type) {
    case SET_APP_CLIENT_DATA: {
      const { patternsState } = action.payload;
      data.patternsState = patternsState;
      return;
    }
    case RESET_APP_CLIENT_DATA:
      data.patternsState = patternsInitialState;
      return;
    case UPDATE_PATTERN:
      draft.patterns[action.payload.id] = action.payload;
      return;

    case DELETE_PATTERN: {
      const { patternId } = action.payload;
      const pattern = draft.patterns[patternId];
      // Iterate over all subPages and remove associated blocks
      pattern.subPages.forEach((subPage) => {
        subPage.blockIds.forEach((blockId) => {
          removeBlock({
            blockId,
            data,
          });
        });
      });
      pattern.templates.forEach((template) => {
        template.demoIds.forEach((demoId) => {
          removeDemoFromSlots({
            draft,
            data,
            patternId,
            templateId: template.id,
            demoId,
          });
          delete data.db.demos.byId[demoId];
        });
      });
      delete draft.patterns[patternId];
      return;
    }
    case 'patterns.updateTitle': {
      const { patternId, title } = action;
      const pattern = state.patterns[patternId];
      if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
      if (pattern.title === title) return;
      pattern.title = title;
      // Update the name of the associated nav item
      data.navsState.byId[patternId].name = title;
      return;
    }

    case ADD_PATTERN: {
      const { patternId, title, excludeOverviewTab } = action.payload;
      draft.patterns[patternId] = {
        id: patternId,
        title,
        description: '',
        statuses: {},
        templates: [],
        tabs: excludeOverviewTab ? [] : [{ type: 'subPage', id: 'overview' }],
        subPages: excludeOverviewTab
          ? []
          : [
              {
                id: 'overview',
                title: 'Overview',
                blockIds: [],
              },
            ],
      };
      trackEvent({
        type: 'Pattern Added',
        metadata: {
          patternId,
        },
      });
      return;
    }

    case ADD_PATTERN_SUB_PAGE: {
      const { patternId, title, id: suggestedId } = action.payload;
      const pattern = draft.patterns[patternId];
      if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
      let id = suggestedId || slugify({ string: title });
      if (!pattern.subPages) pattern.subPages = [];
      const isUniqueId = !pattern.subPages.find((s) => s.id === id);
      id = isUniqueId ? id : `${id}-${makeId()}`;
      pattern.subPages.push({
        title,
        id,
        blockIds: [],
      });
      pattern.tabs.push({
        type: 'subPage',
        id,
      });
      trackEvent({
        type: 'Pattern SubPage Added',
        metadata: {
          patternId,
          title,
        },
      });
      return;
    }

    case DELETE_PATTERN_SUB_PAGE: {
      const { patternId, subPageId } = action.payload;
      const pattern = draft.patterns[patternId];
      if (!pattern) throw new Error(`Could not find pattern "${patternId}"`);
      const subPage = pattern.subPages.find((s) => s.id === subPageId);
      subPage.blockIds.forEach((blockId) => {
        removeBlock({
          blockId,
          data,
        });
      });
      pattern.subPages = pattern.subPages.filter((s) => s.id !== subPageId);
      pattern.tabs = pattern.tabs.filter((t) => t.id !== subPageId);

      trackEvent({
        type: 'Pattern SubPage Deleted',
        metadata: {
          patternId,
        },
      });
      return;
    }

    case ADD_TEMPLATE: {
      const { patterns, renderers } = draft;
      const {
        alias,
        templateLanguageId,
        path,
        templateId,
        patternId,
        assetSetIds,
        title,
        inferSpec,
      } = action.payload;

      const {
        enableDataDemos,
        enableTemplateDemos,
        hasInferSpecSupport,
        title: rendererTitle,
      } = renderers[templateLanguageId].meta;
      const { templates, tabs } = patterns[patternId];
      let id = templateId || templateLanguageId;
      id = templates.find((t) => t.id === id)
        ? `${templateLanguageId}-${makeId()}`
        : id;

      const template: KnapsackPatternTemplate = {
        id,
        title: title || rendererTitle,
        path,
        alias,
        templateLanguageId,
        spec: {},
        assetSetIds,
        demoIds: [],
        blockIds: [],
      };
      if (hasInferSpecSupport && inferSpec === true) {
        template.spec.isInferred = inferSpec;
      }
      if (enableDataDemos) {
        const demo: DataDemo = {
          id: makeId(),
          title: 'Main',
          type: 'data',
          patternId,
          templateId,
          data: {
            props: {},
            slots: {},
          },
        };
        template.demoIds.push(demo.id);
        data.db.demos.byId[demo.id] = demo;
      } else if (enableTemplateDemos) {
        const demo: TemplateDemo = {
          id: makeId(),
          title: 'Main',
          type: 'template',
          templateInfo: {
            // using `template.path` for now so we can get this working quickly
            path,
          },
        };
        template.demoIds.push(demo.id);
        data.db.demos.byId[demo.id] = demo;
      }
      templates.push(template);

      tabs.push({ type: 'template', id });
      trackEvent({
        type: 'Template Added',
        metadata: {
          patternId,
          templateLanguageId,
        },
      });
      return;
    }

    case SET_DEMO_ASSET_SET: {
      const { assetSetId, demoId } = action.payload;
      const demo = data.db.demos.byId[demoId];
      demo.assetSetId = assetSetId;
      return;
    }

    case SET_TEMPLATE_ASSET_SETS: {
      const { patternId, templateId, assetSetIds } = action.payload;
      const template = draft.patterns[patternId].templates.find(
        (t) => t.id === templateId,
      );
      template.assetSetIds = assetSetIds.filter(Boolean);
      return;
    }

    case REORDER_PATTERN_TABS: {
      const { patternId, tabIds } = action.payload;
      const pattern = draft.patterns[patternId];
      pattern.tabs = tabIds.map((id) =>
        pattern.tabs.filter(Boolean).find((t) => t.id === id),
      );
      return;
    }

    case UPDATE_TEMPLATE_INFO: {
      const { patternId, templateId, template } = action.payload;
      const { templates } = draft.patterns[patternId];
      const oldTemplate = templates.find((t) => t.id === templateId);
      mergeDeepInImmer({
        target: oldTemplate,
        source: template,
        sourceIsPartial: true,
      });
      return;
    }

    case REMOVE_TEMPLATE_DEMO: {
      const { templateId, patternId, demoId } = action.payload;
      const pattern = draft.patterns[patternId];
      const template = pattern.templates.find((t) => t.id === templateId);
      delete data.db.demos.byId[demoId];
      template.demoIds = template.demoIds.filter((d) => d !== demoId);

      // searching all other pattern demos to find any slots that used this demo.
      removeDemoFromSlots({
        draft,
        data,
        patternId: action.payload.patternId,
        templateId: action.payload.templateId,
        demoId: action.payload.demoId,
      });
      const { templateLanguageId } = template;
      trackEvent({
        type: 'Demo (data) Deleted',
        metadata: {
          patternId,
          templateLanguageId,
        },
      });
      return;
    }

    case 'patterns.subPage.updateTitle': {
      const { patternId, subPageId, title } = action;
      const pattern = draft.patterns[patternId];
      if (!pattern) throw new Error(`Cannot find pattern "${patternId}"`);
      const subPage = pattern.subPages?.find((s) => s.id === subPageId);
      if (!subPage) throw new Error(`Cannot find subPage "${subPageId}"`);
      subPage.title = title;
      return;
    }

    case DUPLICATE_DEMO: {
      const { patternId, templateId, demoId, newDemoId } = action.payload;
      const template = draft.patterns[patternId]?.templates.find(
        (t) => t.id === templateId,
      );
      const id = newDemoId || makeId();
      const demo = data.db.demos.byId[demoId];
      let { title } = demo;
      // We want to add a number to title: 'Main' => 'Main 2' OR 'Main 3' => 'Main 4'
      const lastCharacter = title.slice(-1);
      const lastAsNum = parseInt(lastCharacter, 10);
      if (Number.isInteger(lastAsNum)) {
        title = `${title.slice(0, -1)}${lastAsNum + 1}`;
      } else {
        title = `${title} 2`;
      }
      template.demoIds.push(id);
      data.db.demos.byId[id] = {
        ...demo,
        title,
        id,
      };
      break;
    }
    case TOGGLE_INFER_SPEC: {
      const { patternId, templateId, isInferred } = action;
      const template = draft.patterns[patternId]?.templates.find(
        (t) => t.id === templateId,
      );
      // is inferred or have props

      if (isInferred) {
        template.spec.isInferred = isInferred;
      } else {
        delete template.spec.isInferred;
      }
      break;
    }

    case UPDATE_SPEC: {
      const { patternId, templateId, spec } = action.payload;
      const template = draft.patterns[patternId]?.templates?.find(
        (t) => t.id === templateId,
      );
      if (!template) {
        throw new Error(
          `Could not find pattern "${patternId}" template "${templateId}"`,
        );
      }
      mergeDeepInImmer({
        target: template.spec,
        source: spec,
        sourceIsPartial: false,
      });
      break;
    }

    case DELETE_TEMPLATE: {
      const { patternId, templateId } = action.payload;
      const pattern = draft.patterns[patternId];
      if (!pattern) {
        throw new Error(`Could not find patternId "${patternId}"`);
      }
      pattern.templates = pattern.templates.filter((t) => t.id !== templateId);
      pattern.tabs = pattern.tabs.filter((t) => t.id !== templateId);
      // const { templateLanguageId } = template;
      trackEvent({
        type: 'Template Deleted',
        metadata: {
          patternId,
          // templateLanguageId,
        },
      });
      break;
    }

    case SET_PATTERN_STATUS: {
      const { patternId, statusSetId, statusId } = action.payload;
      const pattern = draft.patterns[patternId];
      if (!pattern.statuses) pattern.statuses = {};
      pattern.statuses[statusSetId] = statusId;
      break;
    }

    case SET_STATUS_SET: {
      let isNew = true;
      draft.statusSets = draft.statusSets.map((s) => {
        if (s.id !== action.payload.id) return s;
        isNew = false;
        return action.payload;
      });
      if (isNew) draft.statusSets.push(action.payload);
      break;
    }

    case DELETE_STATUS_SET: {
      const { statusSetId } = action.payload;
      draft.statusSets = draft.statusSets.filter((s) => s.id !== statusSetId);
      // Remove value set on any pattern that used this status set
      Object.values(draft.patterns).forEach((pattern) => {
        delete pattern.statuses?.[statusSetId];
      });
      break;
    }
    case 'add.statusSet': {
      const { statusSet } = action;
      draft.statusSets.push(statusSet);
      break;
    }
    case 'update.statusSet': {
      const { statusSet } = action;
      draft.statusSets = draft.statusSets.map((s) => {
        if (s.id !== statusSet.id) return s;
        return statusSet;
      });
      break;
    }
    case 'delete.statusSet': {
      const { statusSetId } = action;
      draft.statusSets = draft.statusSets.filter((s) => s.id !== statusSetId);
      // Remove value set on any pattern that used this status set
      Object.values(draft.patterns).forEach((pattern) => {
        delete pattern.statuses?.[statusSetId];
      });
      break;
    }
    case 'duplicate.statusSet': {
      const { statusSet } = action;
      const newStatusSet = {
        id: makeId(),
        title: `${statusSet.title} - copy`,
        statuses: statusSet.statuses,
      };
      draft.statusSets.push(newStatusSet);
      break;
    }
    case 'statusSets.update': {
      const { statusSets } = action;
      draft.statusSets = statusSets;
      break;
    }
    default: {
      const _exhaustiveCheck: never = action;
    }
  }
}
