import loKeyBy from 'lodash/keyBy';
import type { KnapsackNavItemId, KnapsackNavItem } from '@knapsack/types';

/**
 * Navs byId -> array
 */
export const navByIdToArray = (
  navs: Record<KnapsackNavItemId, KnapsackNavItem>,
  order: KnapsackNavItemId[],
) => order.map((id) => navs[id]);

/**
 * Navs array -> byId
 */
export const navArrayToById = (
  navs: KnapsackNavItem[],
): {
  byId: Record<KnapsackNavItemId, KnapsackNavItem>;
  order: KnapsackNavItemId[];
} => ({
  byId: loKeyBy(navs, 'id'),
  order: navs.map((nav) => nav.id),
});
