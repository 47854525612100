import { GraphQLClient, ClientError } from 'graphql-request';
import {
  getSdk,
  type GitProviders_Enum,
  type Plans_Enum,
  type SiteStatuses_Enum,
} from './temp/graphql-request';

// export * as Dom from 'graphql-request/dist/types.dom';
export * from './temp/graphql-request';
export { ClientError as GraphqlClientError };

export type SiteMeta = {
  siteId: string;
  title: string;
  isPrivate: boolean;
  repoUrl: string;
  gitProviderType: GitProviders_Enum;
  status: SiteStatuses_Enum;
  plan: Plans_Enum;
  orgId: string;
  /**
   * The URL of the production environment
   * Formerly known as `latestUrl`
   */
  prodEnvUrl: string;
};

/**
 * Plan sizes
 * Keys are the DB Enum values - which have to start with a letter
 * Values are the number
 * @example
 * ```ts
 * const planId = 'L40';
 * // `40`
 * const planNumber = planNumbers[planId];
 * ```
 */
export const planNumbers = {
  L20: 20,
  L40: 40,
} as const satisfies Record<Plans_Enum, number>;

/**
 * Use with `gqlClient` to better parse error messages
 * @example
 * try {
 *   const results = await gqlClient.MyRequest();
 * } catch (e) {
 *   const { message } = createReadableErrorMessage(e);
 * }
 */
export function createReadableErrorMessage(
  /** An error thrown by `gqlClient` */
  error: ClientError | Error,
): { message: string; meta?: Record<string, unknown> } {
  if (error instanceof ClientError) {
    const { message, name, request, response, stack } = error;
    const { errors = [] } = response;
    const msgs: string[] = [
      // `GraphQL Error:`,
      ...errors.map(({ path, locations, message: msg }) => `${msg}`),
    ];

    return {
      message: msgs.join('\n '),
      meta: {
        request,
        response,
        stack,
      },
    };
  }
  return {
    message: `${error.name}: ${error.message}`,
  };
}

export type HasuraClient = ReturnType<typeof getSdk>;

const { GRAPHQL_ENDPOINT = 'https://db.knapsack.cloud/v1/graphql' } =
  process.env;

/**
 * Uses the `graphql-request` library that wraps `fetch` to make requests to Hasura GraphQL.
 * @url https://db.knapsack.cloud/v1/graphql
 */
export function createHasuraClient({
  getHeaders,
  gqlServerUrl = GRAPHQL_ENDPOINT,
  logError,
}: {
  getHeaders: () => Promise<Record<string, string>>;
  gqlServerUrl?: string;
  logError?: (error: Error) => void;
}): HasuraClient {
  const client = new GraphQLClient(gqlServerUrl);

  return getSdk(client, async (action) => {
    const headers = await getHeaders();
    Object.entries(headers).forEach(([key, value]) => {
      client.setHeader(key, value);
    });
    try {
      const results = await action();
      return results;
    } catch (err) {
      err.message = createReadableErrorMessage(err).message;
      logError?.(err);
      throw err;
    }
  });
}
