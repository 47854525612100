'use client';

// setting this b/c of the use of `module.hot`
/* eslint-disable import/no-import-module-exports */

import { forwardTo, interpret, Machine } from 'xstate';
import { inspect } from '@xstate/inspect';
import {
  placeholderAction,
  TypedUseSelectorHook,
} from '@/core/xstate/xstate.utils';
import { defaultLdFeatureFlags } from '@/utils/launch-darkly-feature-flags';
import { featureFlags } from '@/utils/feature-flags';
import { isUnitTesting } from '@/utils/constants';
import { isBrowser } from '@knapsack/utils';
import { usersAndSiteStateConfig } from './sub-states/users-and-site.xstate';
import { userStateConfig } from './sub-states/user.xstate';
import { siteStateConfig } from './sub-states/site.xstate';
import {
  AppContext,
  AppEvents,
  AppStateSchema,
  AppTypestates,
  sendUserMessage,
  createAppXstateHooks,
  APP_SUB_MACHINE_IDS,
} from './app.xstate-utils';
import { createUiXstateHooks, type UiInterpreter, uiMachine } from '../ui';
import {
  AppClientDataInterpreter,
  appClientDataMachine,
  createAppClientDataXstateHooks,
  AppClientDataCtx,
} from '../app-client-data';
import { knapsackGlobal } from '../../../../global';

export type { AppContext, AppEvents };

const { enableStateInspector } = featureFlags;

if (enableStateInspector) {
  inspect({
    iframe: false,
  });
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // Changes to this file, and it's imports, often cause App to break when Fast Refresh trys to apply them
  // So we decline Hot Module Replacement for this file and just reload the page
  module.hot.decline();
}

// : await import('@/utils/launch-darkly-feature-flags');
export const appMachine = Machine<AppContext, AppStateSchema, AppEvents>({
  id: 'app',
  context: {
    featureFlags: {
      isHtmlRendererEnabled: true,
      ...defaultLdFeatureFlags,
    },
  },
  strict: true,
  type: 'parallel',
  invoke: [
    {
      id: APP_SUB_MACHINE_IDS.appClientData,
      src: appClientDataMachine,
    },
    {
      id: APP_SUB_MACHINE_IDS.ui,
      src: uiMachine,
    },
  ],
  states: {
    site: siteStateConfig,
    user: isUnitTesting ? {} : userStateConfig,
    usersAndSite: isUnitTesting ? {} : usersAndSiteStateConfig,
  },
  on: {
    'app.sendUserMsg': {
      actions: (ctx, event) => sendUserMessage(event.msg),
    },
    'app.log': {
      actions: [(ctx, event) => console.log(event.msg)],
    },
    'xstate.error': {
      actions: [
        (ctx, event) => {
          console.error(event.type);
          console.error(event.data);
        },
      ],
    },
    'appClientData.changed': {
      actions: [forwardTo(uiMachine.id)],
    },
    'userAndSite.haveBoth': {
      actions: placeholderAction,
    },
    noop: {
      actions: placeholderAction,
    },
  },
});

export const appService = interpret<
  AppContext,
  AppStateSchema,
  AppEvents,
  AppTypestates,
  any
>(appMachine, {
  devTools: enableStateInspector,
});

export const { send: sendAppEvent } = appService;

function log({
  state,
  name,
}: {
  name: string;
  state: {
    event: {
      type: string;
    };
    value?: unknown;
  };
}): void {
  if (isUnitTesting || !isBrowser) return;
  console.debug(
    `[${name}]: "${state.event.type}" Xstate Event`,
    process.env.NODE_ENV === 'production' ? state.event : state, // we log less on prod to not send too much info to bug reporting
    state.value,
  );
}
appService.onTransition((state) => log({ state, name: 'app' }));

appService.start();

export const uiService = appService.children.get(
  APP_SUB_MACHINE_IDS.ui,
) as UiInterpreter;

export const appClientDataService = appService.children.get(
  APP_SUB_MACHINE_IDS.appClientData,
) as AppClientDataInterpreter;
appClientDataService.onTransition((state) => {
  log({ state, name: APP_SUB_MACHINE_IDS.appClientData });
});

if (!uiService) {
  throw new Error('Missing XState uiService');
}
if (!appClientDataService) {
  throw new Error('Missing XState appClientDataService');
}
uiService.subscribe((state) => log({ state, name: APP_SUB_MACHINE_IDS.ui }));

export const { send: sendUiEvent } = uiService;
export const { send: sendAppClientDataEvent } = appClientDataService;

// updating `window.knapsackGlobal` with running State Machines (called Services)
knapsackGlobal.appClientDataService = appClientDataService;
knapsackGlobal.appService = appService;
knapsackGlobal.uiService = uiService;
knapsackGlobal.getState = () => appClientDataService.state.context.active;
knapsackGlobal.dispatch = sendAppClientDataEvent;
knapsackGlobal.isReady = true;
// end updating `window.knapsackGlobal`

export const {
  useStateMatches: useAppStateMatches,
  useStateMatchesOneOf: useAppStateMatchesOneOf,
  useCtxSelector: useAppCtxSelector,
  useOnEvent: useAppOnEvent,
  useIsEventAllowed: useIsAppEventAllowed,
  waitForEvents: waitForAppEvents,
} = createAppXstateHooks(appService);

export const {
  useCtxSelector: useUiCtxSelector,
  useStateMatches: useUiStateMatches,
  useStateMatchesOneOf: useUiStateMatchesOneOf,
  useOnEvent: useUiOnEvent,
  useIsEventAllowed: useIsUiEventAllowed,
} = createUiXstateHooks(uiService as UiInterpreter);

export const {
  /**
   * Get data from App Client Data machine's Context
   * @see {useAppClientDataSelector} if you don't want to select off of `ctx.active` all the time.
   */
  useCtxSelector: useAppClientDataCtxSelector,
  useStateMatches: useAppClientDataStateMatches,
  useStateMatchesOneOf: useAppClientDataStateMatchesOneOf,
  waitForEvents: waitForAppClientDataEvents,
  useIsEventAllowed: useIsAppClientDataEventAllowed,
} = createAppClientDataXstateHooks(appClientDataService);

/**
 * Get data off the active App Client Data
 * It's like `useAppClientDataCtxSelector` except it selects off of `ctx.active`
 * Created since A LOT of selector hooks expect the App Client Data as the param
 * @see {useAppClientDataCtxSelector} selects from full Context
 * @see {useSelector} an alias for this
 * @see {KsAppClientDataNoMeta}
 */
export const useAppClientDataSelector: TypedUseSelectorHook<
  AppClientDataCtx['active']
> = (selector, opt) => {
  return useAppClientDataCtxSelector(({ active }) => selector(active), opt);
};

export {
  /**
   * For Redux backwards compatibility
   * @see {useAppClientDataSelector} this is an alias for that
   * @see {useAppClientDataCtxSelector} selects from full Context
   */
  useAppClientDataSelector as useSelector,
};
