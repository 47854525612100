import {
  TypographyConfig,
  TypographyLinkConfig,
  NavCustomizationConfig,
  PrimaryNavCustomizationConfig,
  SecondaryNavCustomizationConfig,
  FontFaceConfig,
  pageElementsV2,
  customizableNavElements,
  UiConfigStruct,
} from '@knapsack/types';

export interface UiSettings {
  appearance?: {
    pageElements?: {
      pageTitle?: TypographyConfig;
      pageDescription?: TypographyConfig;
      headingXLarge?: TypographyConfig;
      headingLarge?: TypographyConfig;
      headingMedium?: TypographyConfig;
      headingSmall?: TypographyConfig;
      headingXSmall?: TypographyConfig;
      bodyText?: TypographyConfig;
      link?: TypographyLinkConfig;
    };
    navigation?: {
      primaryNav?: PrimaryNavCustomizationConfig;
      secondaryNav?: SecondaryNavCustomizationConfig;
      tabs?: NavCustomizationConfig;
    };
    customFonts?: FontFaceConfig[];
  };
}

export const defaultUiConfigData = {
  version: 10,
  appearance: {
    pageElements: pageElementsV2,
    navigation: customizableNavElements,
    customFonts: [] as FontFaceConfig[],
  },
};

export const defaultUiConfig = UiConfigStruct.create(defaultUiConfigData);
