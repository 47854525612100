import {
  BlockCollectionLocation,
  KsAppClientDataNoMeta,
} from '@knapsack/types';

export const blockCollectionSelector = '[data-target="blockCollection"]';

/**
 * Used for getting the `blockIds` from a `BlockCollectionLocation`
 * @see {@link BlockCollectionLocation}
 */
export function getBlockIds({
  blockCollectionLocation,
  appClientData,
}: {
  blockCollectionLocation: BlockCollectionLocation;
  appClientData: KsAppClientDataNoMeta;
}): { blockIds: string[] } {
  switch (blockCollectionLocation.type) {
    case 'page': {
      const { pageId } = blockCollectionLocation;
      const page = appClientData.customPagesState.pages[pageId];
      if (!page) {
        throw new Error(`Could not find page "${pageId}"`);
      }
      return page;
    }
    case 'patternSubPage': {
      const { patternId, subPageId } = blockCollectionLocation;
      const subPage = appClientData.patternsState.patterns[
        patternId
      ]?.subPages?.find((s) => s.id === subPageId);
      if (!subPage) {
        throw new Error(
          `Could not find subPage "${subPageId}" in pattern "${patternId}"`,
        );
      }
      return subPage;
    }
    case 'patternTemplate': {
      const { patternId, templateId } = blockCollectionLocation;
      const template = appClientData.patternsState.patterns[
        patternId
      ]?.templates?.find((t) => t.id === templateId);
      if (!template) {
        throw new Error(
          `Could not find template "${templateId}" in pattern "${patternId}"`,
        );
      }
      return template;
    }
    default: {
      const _exhaustiveCheck: never = blockCollectionLocation;
      throw new Error(
        `Unhandled blockCollectionLocation type: ${JSON.stringify(
          blockCollectionLocation,
        )}`,
      );
    }
  }
}

/**
 * Keys are Block IDs, values are the location of the block collection is in - {@linkcode BlockCollectionLocation}
 * Useful to build once before looking up many blocks.
 */
export function buildBlockLocationMap({
  appClientData: {
    customPagesState: { pages },
    patternsState: { patterns },
  },
}: {
  // doing this to make testing easier, passing in full `KsAppClientDataNoMeta` will still work fine
  appClientData: {
    customPagesState: KsAppClientDataNoMeta['customPagesState'];
    patternsState: KsAppClientDataNoMeta['patternsState'];
  };
}): Record<string, BlockCollectionLocation> {
  const blockLocationMap: Record<string, BlockCollectionLocation> = {};

  Object.values(pages).forEach((page) => {
    page.blockIds.forEach((blockId) => {
      blockLocationMap[blockId] = {
        type: 'page',
        pageId: page.id,
      };
    });
  });

  Object.values(patterns).forEach(({ id: patternId, subPages, templates }) => {
    subPages?.forEach((subPage) => {
      subPage.blockIds.forEach((blockId) => {
        blockLocationMap[blockId] = {
          type: 'patternSubPage',
          patternId,
          subPageId: subPage.id,
        };
      });
    });

    templates?.forEach((template) => {
      template.blockIds.forEach((blockId) => {
        blockLocationMap[blockId] = {
          type: 'patternTemplate',
          patternId,
          templateId: template.id,
        };
      });
    });
  });

  return blockLocationMap;
}

export function findBlock({
  blockId,
  appClientData,
}: {
  blockId: string;
  // doing this to make testing easier, passing in full `KsAppClientDataNoMeta` will still work fine
  appClientData: {
    customPagesState: KsAppClientDataNoMeta['customPagesState'];
    patternsState: KsAppClientDataNoMeta['patternsState'];
  };
}): BlockCollectionLocation {
  const blockLocationMap = buildBlockLocationMap({ appClientData });
  const blockLocation = blockLocationMap[blockId];
  if (blockLocation) return blockLocation;
  throw new Error(`Could not find block ${blockId}`);
}

export function getBlockCollectionTitle({
  appClientData: {
    customPagesState: { pages },
    patternsState: { patterns },
  },
  blockCollectionLocation: bcl,
}: {
  blockCollectionLocation: BlockCollectionLocation;
  // doing this to make testing easier, passing in full `KsAppClientDataNoMeta` will still work fine
  appClientData: {
    customPagesState: KsAppClientDataNoMeta['customPagesState'];
    patternsState: KsAppClientDataNoMeta['patternsState'];
  };
}): string {
  switch (bcl.type) {
    case 'page': {
      return pages[bcl.pageId]?.title;
    }
    case 'patternSubPage': {
      const pattern = patterns[bcl.patternId];
      const subPage = pattern.subPages?.find((s) => s.id === bcl.subPageId);
      return `${pattern.title} > ${subPage?.title}`;
    }
    case 'patternTemplate': {
      const pattern = patterns[bcl.patternId];
      const template = pattern.templates.find((t) => t.id === bcl.templateId);
      return `${pattern.title} > ${template.title}`;
    }
    default: {
      const _check: never = bcl;
      throw new Error(
        `Unknown Block Collection Type: '${JSON.stringify(bcl)}'`,
      );
    }
  }
}
