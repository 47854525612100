import { makeShortId, makeUuid } from '@knapsack/utils';

/**
 * @deprecated
 * Use `import { makeShortId, makeUuid } from '@knapsack/utils';
` instead
 */
export const makeId = ({
  isShort = true,
}: {
  /** If not short, then it's a UUID */
  isShort?: boolean;
} = {}): string => (isShort ? makeShortId() : makeUuid());
