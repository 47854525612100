import { Machine, send, Sender, actions } from 'xstate';
import { assign } from '@xstate/immer';
import { getXstateUtils } from '@/core/xstate/xstate.utils';
import { DEFAULT_FAVICON_URL, isUnitTesting } from '@/utils/constants';
import { navByIdToArray } from '@/domains/navs/utils/nav-data';
import deepEqual from 'deep-equal';
import { isBrowser } from '@knapsack/utils';
import { SET_APP_CLIENT_DATA } from '../app-client-data/reducers/shared.xstate';
import type {
  WinWidthNames,
  UiCtx,
  UiState,
  UiEvents,
  UiTypestates,
} from './types';
import { getWinWidthName, handleNavChanges } from './utils';

const { createXstateHooks: createUiXstateHooks } = getXstateUtils<
  UiCtx,
  UiEvents,
  UiTypestates,
  UiState
>();

export { createUiXstateHooks };

const isWindowWideEnoughForSidebar = ({ winWidthName }: UiCtx) =>
  winWidthName === 'large' || winWidthName === 'xlarge';

export const uiMachine = Machine<UiCtx, UiState, UiEvents>({
  id: 'ui',
  type: 'parallel',
  strict: true,
  context: {
    isLoggedIn: false,
    winWidthName:
      isUnitTesting || !isBrowser
        ? 'large'
        : getWinWidthName(window.innerWidth),
    nav: {
      source: [],
      top: [],
      activeSubNavId: '',
    },
    nestedDemos: [],
    demos: {},
    demosUnsaved: {},
    headTags: {
      title: 'Knapsack',
      description: 'Design System platform',
      favicon: DEFAULT_FAVICON_URL,
    },
    commandBar: {
      searchValue: '',
      selectedList: { id: 'home' },
    },
  },
  invoke: {
    id: 'windowWidthWatcher',
    src: (ctx) => (sendEvent: Sender<UiEvents>) => {
      if (!isBrowser) return;
      const delay = 1000;
      let timeoutId: NodeJS.Timeout;
      let currentWidthName: WinWidthNames = ctx.winWidthName;

      function handleResize({ currentTarget }: WindowEventMap['resize']): void {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          const { innerWidth } = currentTarget as Window;
          const widthName = getWinWidthName(innerWidth);
          if (widthName === currentWidthName) return;
          currentWidthName = widthName;
          switch (widthName) {
            case 'xlarge':
              sendEvent('windowWidth.isXLarge');
              break;
            case 'large':
              sendEvent('windowWidth.isLarge');
              break;
            case 'medium':
              sendEvent('windowWidth.isMedium');
              break;
            case 'small':
              sendEvent('windowWidth.isSmall');
              break;
            case 'xsmall':
              sendEvent('windowWidth.isXSmall');
              break;
          }
        }, delay);
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },
  },
  on: {
    'user.authed': {
      actions: [
        assign((ctx) => {
          ctx.isLoggedIn = true;
        }),
      ],
    },
    'user.unload': {
      actions: [
        assign((ctx) => {
          ctx.isLoggedIn = false;
        }),
      ],
    },
    'windowWidth.isXLarge': {
      actions: [
        assign((ctx) => {
          ctx.winWidthName = 'xlarge';
        }),
      ],
    },
    'windowWidth.isLarge': {
      actions: [
        assign((ctx) => {
          ctx.winWidthName = 'large';
        }),
      ],
    },
    'windowWidth.isMedium': {
      actions: [
        assign((ctx) => {
          ctx.winWidthName = 'medium';
        }),
      ],
    },
    'windowWidth.isSmall': {
      actions: [
        assign((ctx) => {
          ctx.winWidthName = 'small';
        }),
      ],
    },
    'windowWidth.isXSmall': {
      actions: [
        assign((ctx) => {
          ctx.winWidthName = 'xsmall';
        }),
      ],
    },
    'toc.setHeadings': {
      actions: assign((ctx, { headings }) => {
        ctx.toc = ctx.toc || { headings: [] };
        ctx.toc.headings = headings;
      }),
    },
    'nav.setActiveTopNavItem': {
      actions: assign((ctx, { activeTopNavItem }) => {
        ctx.nav.activeTopNavItem = activeTopNavItem;
        ctx.nav.expandedNavIds = []; // reset expandedNavIds
      }),
    },
    'nav.setExpandedNavIds': {
      actions: assign((ctx, { add, remove }) => {
        const {
          nav: { expandedNavIds: currentExpandedNavIds = [] },
        } = ctx;

        if (remove) {
          ctx.nav.expandedNavIds = currentExpandedNavIds.filter(
            (id) => id !== remove,
          );
          return;
        }
        if (add) {
          ctx.nav.expandedNavIds = [
            ...currentExpandedNavIds.filter((id) => id !== add),
            add,
          ].filter(Boolean);
        }
      }),
    },
    'nav.locationChanged': {
      actions: [
        assign((ctx) => {
          handleNavChanges(ctx);
        }),
      ],
    },
    // i.e. "knapsack/Set App Client Data"
    [SET_APP_CLIENT_DATA]: {
      actions: assign((ctx, { payload }) => {
        const { navsState } = payload;
        ctx.nav.source = navByIdToArray(navsState.byId, navsState.order);
        handleNavChanges(ctx);
      }),
    },
    // happens when users change it via UI
    'appClientData.changed': {
      actions: assign((ctx, { appClientData }) => {
        const { navsState } = appClientData;
        ctx.nav.source = navByIdToArray(navsState.byId, navsState.order);
        handleNavChanges(ctx);
      }),
    },
    'headTags.set': {
      actions: assign((ctx, { headTags }) => {
        Object.assign(ctx.headTags, headTags);
      }),
    },
  },
  states: {
    commandBar: {
      initial: 'closed',
      states: {
        closed: {
          on: {
            'commandBar.open': {
              target: 'opened',
              actions: assign((ctx, { list }) => {
                if (list) {
                  ctx.commandBar = { selectedList: list };
                } else {
                  ctx.commandBar = { selectedList: { id: 'home' } };
                }
              }),
            },
          },
        },
        opened: {
          on: {
            'commandBar.close': 'closed',
            'commandBar.setList': {
              actions: assign((ctx, { list }) => {
                ctx.commandBar.selectedList = list;
                ctx.commandBar.searchValue = '';
              }),
            },
            'commandBar.setSearchValue': {
              actions: assign((ctx, { value }) => {
                ctx.commandBar.searchValue = value;
              }),
            },
          },
        },
      },
    },
    dnd: {
      initial: 'enabled',
      states: {
        dragging: {
          entry: () => {
            document.body.classList.add('is-drag-and-dropping');
          },
          exit: () => {
            document.body.classList.remove('is-drag-and-dropping');
          },
          on: {
            'dnd.stoppedDragging': 'enabled',
          },
        },
        enabled: {
          on: {
            'dnd.startedDragging': 'dragging',
            'dnd.triggerOff': 'disabled',
          },
        },
        disabled: {
          on: {
            'dnd.triggerOn': 'enabled',
          },
        },
      },
    },
    modal: {
      initial: 'closed',
      on: {
        'modal.setContent': {
          actions: actions.assign((ctx, { modal }) => {
            return {
              ...ctx,
              modal,
            };
          }),
        },
      },
      states: {
        closed: {
          id: 'modalClosed',
          on: {
            'modal.triggerOpen': 'opened',
            'modal.triggerToggle': 'opened',
          },
          entry: send(
            { type: 'modal.setContent', modal: null },
            { delay: 500 },
          ),
        },
        opened: {
          on: {
            'modal.triggerClose': '#modalClosed',
            'modal.triggerToggle': '#modalClosed',
          },
        },
      },
    },
    navEdit: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            'nav.startedEditing': 'editing',
          },
        },
        editing: {
          on: {
            'nav.stoppedEditing': 'idle',
          },
        },
      },
    },
    portal: {
      initial: 'closed',
      on: {
        'portal.setContent': {
          actions: actions.assign((ctx, { portal }) => {
            // There can be a weird bug ("Cannot assign to read only property '_status' of object") with lazy loading components and immer when use `ctx.portal = portal;` Using the original `actions.assign` seems to make it work.
            return {
              ...ctx,
              portal,
            };
          }),
        },
      },
      states: {
        closed: {
          id: 'portalClosed',
          on: {
            'portal.triggerOpen': 'opened',
            'portal.triggerToggle': 'opened',
          },
          // @todo: delay removing content until portal is hidden
          entry: assign((ctx) => {
            ctx.portal = null;
          }),
        },
        opened: {
          initial: 'unlocked',
          states: {
            unlocked: {
              on: {
                'portal.triggerClose': '#portalClosed',
                'portal.triggerToggle': '#portalClosed',
                'portal.lock': {
                  target: 'locked',
                  actions: assign((ctx, { reason }) => {
                    ctx.portal.lockReason = reason;
                  }),
                },
              },
            },
            locked: {
              on: {
                'portal.unlock': {
                  target: 'unlocked',
                  actions: assign((ctx) => {
                    ctx.portal.lockReason = null;
                  }),
                },
              },
            },
          },
        },
      },
    },
    rightPanel: {
      initial: 'closed',
      on: {
        'rightPanel.setContent': {
          actions: assign((ctx, { rightPanel }) => {
            ctx.rightPanel = rightPanel;
          }),
        },
        'nestedDemos.open': {
          target: '.opened',
          actions: assign((ctx, { demo, replaceAllExisting, slotName }) => {
            if (replaceAllExisting) {
              ctx.nestedDemos = [{ demoId: demo.id, slotName }];
            } else {
              ctx.nestedDemos.push({ demoId: demo.id, slotName });
            }
            if (ctx.demos[demo.id]) {
              // user had already opened this demo, so we'll preserve their existing changes
              return;
            }
            ctx.demos[demo.id] = {
              orig: demo,
              current: demo,
              hasChanges: false,
            };
          }),
        },
        'demos.clearAll': {
          actions: assign((ctx) => {
            ctx.demos = {};
            ctx.demosUnsaved = {};
          }),
        },
        'demos.clearOne': {
          actions: assign((ctx, { demoId }) => {
            delete ctx.demosUnsaved[demoId];
            delete ctx.demos[demoId];
          }),
        },
        'demos.setData': {
          actions: assign((ctx, { demo }) => {
            if (!ctx.demos[demo.id]) {
              ctx.demos[demo.id] = {
                orig: demo,
                current: demo,
                hasChanges: false,
              };
              return;
            }
            const hasChanges = !deepEqual(ctx.demos[demo.id].orig, demo);
            ctx.demos[demo.id] = {
              ...ctx.demos[demo.id],
              current: demo,
              hasChanges,
            };
            if (hasChanges) {
              ctx.demosUnsaved[demo.id] = demo;
            } else {
              delete ctx.demosUnsaved[demo.id];
            }
          }),
        },
        'nestedDemos.closeLast': [
          {
            cond: (ctx) => ctx.nestedDemos.length > 1,
            actions: assign((ctx) => {
              // remove last item of array
              ctx.nestedDemos.pop();
            }),
          },
          {
            cond: (ctx) => ctx.nestedDemos.length < 2,
            target: '.closed',
            actions: [
              assign((ctx) => {
                ctx.nestedDemos = [];
              }),
              send({
                type: 'sidebarDocument.triggerOpenIfWideEnough',
              } satisfies UiEvents),
            ],
          },
        ],
        'nestedDemos.closeAll': {
          target: '.closed',
          actions: [
            assign((ctx) => {
              ctx.nestedDemos = [];
            }),
            send({
              type: 'sidebarDocument.triggerOpenIfWideEnough',
            } satisfies UiEvents),
          ],
        },
      },
      states: {
        closed: {
          on: {
            'rightPanel.triggerOpen': 'opened',
            'rightPanel.triggerToggle': 'opened',
          },
          entry: assign((ctx) => {
            ctx.rightPanel = null;
          }),
        },
        opened: {
          on: {
            'rightPanel.triggerClose': 'closed',
            'rightPanel.triggerToggle': 'closed',
          },
        },
      },
    },
    sidebarDesign: {
      initial: 'initial',
      states: {
        initial: {
          always: [
            {
              cond: isWindowWideEnoughForSidebar,
              target: 'opened',
            },
            {
              target: 'closed',
            },
          ],
        },
        closed: {
          on: {
            'sidebarDesign.triggerOpen': 'opened',
            'sidebarDesign.triggerToggle': 'opened',
            'sidebarDesign.triggerOpenIfWideEnough': {
              target: 'opened',
              cond: isWindowWideEnoughForSidebar,
            },
          },
        },
        opened: {
          on: {
            'sidebarDesign.triggerClose': 'closed',
            'sidebarDesign.triggerToggle': 'closed',
          },
        },
      },
    },
    sidebarDocument: {
      initial: 'initial',
      states: {
        initial: {
          always: [
            {
              cond: isWindowWideEnoughForSidebar,
              target: 'opened',
            },
            {
              target: 'closed',
            },
          ],
        },
        closed: {
          on: {
            'sidebarDocument.triggerOpenIfWideEnough': {
              target: 'opened',
              cond: isWindowWideEnoughForSidebar,
            },
            'sidebarDesign.triggerOpen': 'opened',
            'sidebarDocument.triggerToggle': 'opened',
          },
        },
        opened: {
          on: {
            'sidebarDocument.triggerClose': 'closed',
            'sidebarDocument.triggerToggle': 'closed',
            'nav.locationChanged': [
              {
                cond: (ctx) => !isWindowWideEnoughForSidebar(ctx),
                target: 'closed',
              },
            ],
          },
        },
      },
    },
    sidebarSettings: {
      initial: 'initial',
      states: {
        initial: {
          always: [
            {
              cond: isWindowWideEnoughForSidebar,
              target: 'opened',
            },
            {
              target: 'closed',
            },
          ],
        },
        closed: {
          on: {
            'sidebarSettings.triggerOpen': 'opened',
            'sidebarSettings.triggerToggle': 'opened',
            'sidebarSettings.triggerOpenIfWideEnough': {
              target: 'opened',
              cond: isWindowWideEnoughForSidebar,
            },
          },
        },
        opened: {
          on: {
            'sidebarSettings.triggerClose': 'closed',
            'sidebarSettings.triggerToggle': 'closed',
          },
        },
      },
    },
  },
});
