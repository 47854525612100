import { UiConfig } from '@knapsack/types';
import { isEmpty } from '@knapsack/utils';
import { defaultUiConfig } from './ui-settings';

export function migrateUiConfig(unknownUiConfig: UiConfig): UiConfig {
  if (!unknownUiConfig || isEmpty(unknownUiConfig)) {
    return defaultUiConfig;
  }
  return unknownUiConfig;
}
