import { KsAppClientDataNoMeta, migrations } from '@knapsack/types';
import {
  designSrcReducer,
  DesignSrcActions,
} from '@/domains/design-srcs/design-srcs.xstate';
import { Draft, isDraft } from 'immer';
import {
  ActionMap,
  ExtractActionsFromActionMap,
  getActionHandlerFromMap,
  hasActionOnMap,
} from '@/core/xstate/xstate.utils';
import { Env } from '@/core/env-and-content-src/env-and-content-src.types';
import assetSetsReducer, {
  AssetSetsActions,
  assetSetsInitialState,
} from './asset-sets.xstate';
import customPagesReducer, {
  CustomPagesActions,
  customPagesInitialState,
} from './custom-pages.xstate';
import navReducer, { NavActions, navInitialState } from './navs.xstate';
import patternsReducer, {
  PatternsActions,
  patternsInitialState,
} from './patterns.xstate';
import tokensReducer, {
  TokenEvents,
  TokensActions,
  tokensInitialState,
} from './tokens.xstate';
import filesReducer, { FilesActions, filesInitialState } from './files.xstate';
import dbReducer, { DbActions, dbInitialState } from './db.xstate';

export { type TokenEvents } from './tokens.xstate';

/**
 * Used to alter/update/migrate older App Client Data before it reaches React
 */
function migrateAppClientData(data: Draft<KsAppClientDataNoMeta>): void {
  migrations.forEach((migration) => {
    if (migration.checkIfNeeded(data)) {
      migration.migrate(data);
    }
  });
}

const miscActionMap = {
  'env.switch': (
    data,
    { env: { assetSetsState, renderersMeta } }: { env: Env },
  ) => {
    data.assetSetsState = assetSetsState;
    data.patternsState.renderers = renderersMeta;
  },
} satisfies ActionMap;

export type AppClientDataReducerEvents =
  | ExtractActionsFromActionMap<typeof miscActionMap>
  | PatternsActions
  | NavActions
  | CustomPagesActions
  | AssetSetsActions
  | TokensActions
  | FilesActions
  | DbActions
  | DesignSrcActions;

/**
 * These are the events that should not be sent to the `rootReducer`,
 * but instead should be sent to web worker that uses `TokensSrcUpdater`
 */
export function isTokenEvent(action: { type: string }): action is TokenEvents {
  return action.type.startsWith('tokens.');
}
export function rootReducer(
  data: Draft<KsAppClientDataNoMeta>,
  event: { type: string },
): void {
  if (!isDraft(data)) {
    throw new Error(`Tried to run "rootReducer" but it is not an Immer Draft`);
  }
  if (hasActionOnMap(event, miscActionMap)) {
    getActionHandlerFromMap(event, miscActionMap)(data, event);
    return;
  }
  patternsReducer(data, event as PatternsActions);
  assetSetsReducer(data, event as AssetSetsActions);
  customPagesReducer(data, event as CustomPagesActions);
  navReducer(data, event as NavActions);
  tokensReducer(data, event as TokensActions);
  filesReducer(data, event as FilesActions);
  dbReducer(data, event as DbActions);
  // begin domain specific reducers
  designSrcReducer({
    data,
    action: event as DesignSrcActions,
  });
  // end domain specific reducers
  if (event.type === 'knapsack/SET_APP_CLIENT_DATA') {
    migrateAppClientData(data);
  }
}

export const emptyAppClientData: KsAppClientDataNoMeta = {
  patternsState: patternsInitialState,
  customPagesState: customPagesInitialState,
  assetSetsState: assetSetsInitialState,
  navsState: navInitialState,
  tokensSrc: tokensInitialState,
  filesState: filesInitialState,
  db: dbInitialState,
};
