/**
 * LaunchDarkly Feature Flags
 * [Docs](https://docs.launchdarkly.com/home)
 * [JS SDK Docs](https://docs.launchdarkly.com/sdk/client-side/javascript)
 * [App](https://app.launchdarkly.com)
 * */
import type {
  LDUser as LDUserBase,
  LDClient,
} from 'launchdarkly-js-client-sdk';
import { initialize } from 'launchdarkly-js-client-sdk';
import type { RequiredProps } from '@/utils/type-utils';
import type { Role } from '@knapsack/types';
// if this file is missing, run `npm run launch-darkly:build`
import defaultLdFeatureFlagsJson from './launch-darkly-feature-flags.generated.json';

// This type infers from the generated JSON which is just the feature flags for
// an anonymous user, which is missing the possible `string` variations from
// multi-variant flags. So we have a chance to extend & refine it below.
type LaunchDarklyFeatureFlagsGenerated = typeof defaultLdFeatureFlagsJson;
// This adds more specific type information than above by refining types like
// `string` into an actual union of the possible string values like
// `'sorta' | 'kinda' | 'super'`
export interface LaunchDarklyFeatureFlags
  extends LaunchDarklyFeatureFlagsGenerated {
  // Add more specific types here
}
export type FeatureFlagKey = keyof Partial<LaunchDarklyFeatureFlags>;

// using `as` b/c the extra value type refinements are missing from the default's export
export const defaultLdFeatureFlags =
  defaultLdFeatureFlagsJson as LaunchDarklyFeatureFlags;

// Type guard for working with flags
export const isFeatureFlagKey = (
  flagName: string,
): flagName is FeatureFlagKey => {
  return flagName in defaultLdFeatureFlags;
};

interface LdCustomAttrBase {
  isSuperAdmin: boolean;
  isSiteSpecific: boolean;
  userId: string;
}

interface LdCustomAttrWithSite extends LdCustomAttrBase {
  isSiteSpecific: true;
  roleForSite: Role;
  siteId: string;
}
interface LdCustomAttrWithoutSite extends LdCustomAttrBase {
  isSiteSpecific: false;
}

type LdCustomAttr = LdCustomAttrWithSite | LdCustomAttrWithoutSite;

type LDUser = RequiredProps<LDUserBase, 'key'> & {
  custom: LdCustomAttr;
};

const anonKey = 'all-anonymous-users-key';

export const anonUserWithoutSite: LDUser = {
  anonymous: true,
  key: anonKey,
  custom: {
    isSiteSpecific: false,
    isSuperAdmin: false,
    userId: anonKey,
  },
};

const launchDarklyClientKey = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_KEY;
if (!launchDarklyClientKey) {
  throw new Error('NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_KEY is not set');
}
let ldClient: LDClient;

/**
 * Wait for initialization, can be called any number of  times safely
 */
export async function initLaunchDarkly(): Promise<LDClient> {
  ldClient = initialize(launchDarklyClientKey, anonUserWithoutSite, {
    fetchGoals: false,
    bootstrap: 'localStorage',
  });
  try {
    await ldClient.waitForInitialization();
  } catch (e) {
    if (e instanceof Error)
      throw new Error(
        `Something went wrong in Launch Darkly initialization: ${e.message}`,
      );
  }
  return ldClient;
}

function assertReady() {
  if (!ldClient) {
    throw new Error('LaunchDarkly client not initialized');
  }
}
export function identifyLdAnonUserWithoutSite(): void {
  assertReady();
  ldClient.identify({
    ...anonUserWithoutSite,
    custom: {
      ...anonUserWithoutSite.custom,
      isSiteSpecific: false,
    },
  });
}

export function identifyLdAnonUserWithSite({
  siteId,
}: {
  siteId: string;
}): void {
  assertReady();
  ldClient.identify({
    ...anonUserWithoutSite,
    custom: {
      ...anonUserWithoutSite.custom,
      isSiteSpecific: true,
      roleForSite: 'ANONYMOUS',
      siteId,
    },
  });
}

export function identifyLdUserWithoutSite({
  userId,
  email,
  profilePic,
  isSuperAdmin,
}: {
  userId: string;
  profilePic?: string;
  email: string;
  isSuperAdmin: boolean;
}): void {
  assertReady();
  ldClient.identify({
    key: userId,
    avatar: profilePic,
    email,
    custom: {
      isSiteSpecific: false,
      isSuperAdmin,
      userId,
    },
  });
}

export function identifyLdUserWithSite({
  userId,
  siteId,
  email,
  profilePic,
  isSuperAdmin,
  roleForSite,
}: {
  userId: string;
  siteId: string;
  profilePic?: string;
  email: string;
  isSuperAdmin: boolean;
  roleForSite: Role;
}): void {
  assertReady();
  ldClient.identify({
    key: `${userId}--${siteId}`,
    avatar: profilePic,
    email,
    custom: {
      isSiteSpecific: true,
      isSuperAdmin,
      userId,
      siteId,
      roleForSite,
    },
  });
}

/**
 * Adds extra user profile info that comes in a bit later than the authentication
 * of the user. We break it up so we can load their feature flags as quick as
 * possible w/o having to wait for trivial things like the profilePic.
 */
export async function addProfileInfoToCurrentLdUser({
  profilePic,
  firstName,
  lastName,
}: {
  profilePic: string;
  firstName?: string;
  lastName?: string;
}): Promise<void> {
  const user = ldClient.getContext();
  if (!user) return;
  if (user.anonymous) return;
  ldClient.identify({
    ...user,
    avatar: profilePic,
    firstName,
    lastName,
  });
}
