import { createHasuraClient } from '@knapsack/hasura-gql-client';
import { captureException } from '@/utils/sentry';
import { getHttpHeaders } from './http-headers-utils';

export * from '@knapsack/hasura-gql-client';

const { GRAPHQL_ENDPOINT = 'https://db.knapsack.cloud/v1/graphql' } =
  process.env;

export const hasuraGql = createHasuraClient({
  gqlServerUrl: GRAPHQL_ENDPOINT,
  getHeaders: async () => {
    const baseHeaders = await getHttpHeaders();
    const headers: Record<string, string> = {
      'Hasura-Client-Name': 'app-ui--graphql-request',
      ...baseHeaders,
    };
    return headers;
  },
  logError: (error) => captureException(error),
});
