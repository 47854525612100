import {
  BlockConfig,
  DataDemo,
  KnapsackPatternTemplate,
  KsAppClientDataNoMeta,
  PatternsState,
  SlottedData,
  isDataDemo,
  isSlottedTemplateDemo,
} from '@knapsack/types';
import { isObject, makeShortId } from '@knapsack/utils';
import isArray from 'lodash/isArray';
import { trackEvent } from '@/utils/analytics';
import { isDraft, type Draft, produce } from 'immer';

/**
 * Returns all blocks of a specific type.
 */
export function getBlocksByType<T extends BlockConfig['blockType']>({
  blockType,
  data,
}: {
  blockType: T;
  data: Draft<KsAppClientDataNoMeta>;
}) {
  return Object.values(data.db.blocks.byId).filter(
    (b): b is BlockConfig<T> => b.blockType === blockType,
  );
}

/**
 * Warning: leaves the blockId in the container's `blockIds` array.
 * Use `removeBlockFromContainer` if you want that removed for you.
 * This function is useful if a whole page is being deleted.
 */
export function removeBlock({
  blockId,
  data,
}: {
  blockId: string;
  data: Draft<KsAppClientDataNoMeta>;
}) {
  if (!isDraft(data)) {
    throw new Error('Data must be a draft');
  }
  const block = data.db.blocks.byId[blockId];

  switch (block.blockType) {
    case 'files-list': {
      const { files } = block.data;
      files.forEach((fileId) => {
        const filesListBlocks = getBlocksByType({
          blockType: 'files-list',
          data,
        });
        const isFileUsed = filesListBlocks.some(
          (b) => b.id !== blockId && b.data.files.includes(fileId),
        );
        if (!isFileUsed) {
          delete data.filesState.files[fileId];
        }
      });
      break;
    }
  }

  delete data.db.blocks.byId[blockId];
  trackEvent({
    type: 'Block Deleted',
  });
}

/**
 * Removes a blockId from a container and deletes the block from the data store.
 */
export function removeBlockFromContainer<
  ContainerType extends {
    blockIds: string[];
  },
>({
  blockId,
  container,
  data,
}: {
  blockId: string;
  /** what contains a `blocks` array to remove `blockId` from */
  container: ContainerType;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  if (!container.blockIds) return;

  removeBlock({ blockId, data });
  container.blockIds = container.blockIds.filter((bId) => bId !== blockId);
}

function filterSlotData(
  slotData: SlottedData,
  patternId: string,
  templateId: string,
  demoId: string,
): boolean {
  return (
    !isSlottedTemplateDemo(slotData) ||
    slotData.patternId !== patternId ||
    slotData.templateId !== templateId ||
    slotData.demoId !== demoId
  );
}

function updateDemoSlots(
  demo: DataDemo,
  patternId: string,
  templateId: string,
  demoId: string,
) {
  Object.entries(demo.data.slots || {}).forEach(([slotName, slotDatas]) => {
    if (!isArray(slotDatas)) return;
    demo.data.slots[slotName] = slotDatas.filter((slotData: any) =>
      filterSlotData(slotData, patternId, templateId, demoId),
    );
  });
}

function processTemplates(
  templates: KnapsackPatternTemplate[],
  data: KsAppClientDataNoMeta,
  patternId: string,
  templateId: string,
  demoId: string,
) {
  templates.forEach((template) => {
    template.demoIds
      .map((id) => data.db.demos.byId[id])
      .filter(isDataDemo)
      .filter((demo) => demo.data?.slots)
      .forEach((demo) => updateDemoSlots(demo, patternId, templateId, demoId));
  });
}

/**
 * Search through all patterns and templates and remove the demo from any slots.
 */
export function removeDemoFromSlots({
  draft,
  data,
  patternId,
  templateId,
  demoId,
}: {
  draft: PatternsState;
  data: KsAppClientDataNoMeta;
  patternId: string;
  templateId: string;
  demoId: string;
}) {
  Object.values(draft.patterns).forEach(({ templates }) => {
    processTemplates(templates, data, patternId, templateId, demoId);
  });
}

/**
 * Deep merge two objects inside of an immer draft.
 * Similar to how `Object.assign()` makes smaller patches.
 * However that only merges one level deep.
 * This function will merge deeply.
 * Originally found at https://stackoverflow.com/a/34749873
 */
export function mergeDeepInImmer({
  target,
  source,
  sourceIsPartial,
}: {
  target: Draft<Record<string, any>>;
  source: Record<string, any>;
  /** Set to `true` if `source` only contains some of `target`. If `false`, then any keys in `source` that are not in `target` will be removed from the `target`. */
  sourceIsPartial: boolean;
}) {
  if (!isObject(target)) {
    throw new Error('Target must be an object in mergeDeepInImmer');
  }
  if (!isObject(source)) {
    throw new Error('Source must be an object in mergeDeepInImmer');
  }
  if (!sourceIsPartial) {
    // if keys are not in `source`, they should be removed from the `target`
    Object.keys(target).forEach((key) => {
      if (!(key in source)) {
        delete target[key];
      }
    });
  }

  Object.entries(source).forEach(([sourceKey, sourceValue]) => {
    if (isObject(sourceValue) && isObject(target[sourceKey])) {
      mergeDeepInImmer({
        target: target[sourceKey],
        source: sourceValue,
        sourceIsPartial,
      });
    } else if (target[sourceKey] !== sourceValue) {
      // only set it if it is different
      target[sourceKey] = sourceValue;
    }
  });
}

export function duplicateDataDemo({
  demo: topDemo,
  newDemoId: topDemoId,
  demosById,
}: {
  demo: DataDemo;
  demosById: KsAppClientDataNoMeta['db']['demos']['byId'];
  newDemoId: string;
}): {
  newDemoIds: string[];
  newDemos: Record<string, DataDemo>;
} {
  const newDemoIds: string[] = [];
  const newDemos: Record<string, DataDemo> = {};

  function dupDemoRecursive(demo: DataDemo, demoId = makeShortId()): DataDemo {
    const newDemo = produce(demo, (draft) => {
      draft.id = demoId;
      for (const slotDatas of Object.values(draft.data.slots || {})) {
        for (const slotData of slotDatas) {
          switch (slotData.type) {
            case 'text':
            case 'template-reference':
              break;
            case 'template-demo': {
              const slotDataDemo = demosById[slotData.demoId];
              if (!slotDataDemo) {
                throw new Error(`Demo not found - ${slotData.demoId}`);
              }
              if (slotDataDemo.type !== 'data') {
                throw new Error(
                  `Can only duplicate data demos, not ${slotDataDemo.type} - ${slotDataDemo.id}`,
                );
              }
              const { id: newDemoId } = dupDemoRecursive(slotDataDemo);
              slotData.demoId = newDemoId;
              break;
            }
            default: {
              const _exhaustiveCheck: never = slotData;
            }
          }
        }
      }
    });

    newDemoIds.push(newDemo.id);
    newDemos[newDemo.id] = newDemo;
    return newDemo;
  }

  const newDemo = dupDemoRecursive(topDemo, topDemoId);
  newDemoIds.push(newDemo.id);
  return {
    newDemoIds,
    newDemos: {
      ...newDemos,
      [newDemo.id]: newDemo,
    },
  };
}
