import { isUnitTesting } from '@/utils/constants';
import { isBrowser } from '@knapsack/utils';

const storageMock: Storage = {
  removeItem: () => {},
  setItem: () => {},
  getItem: () => '',
  length: 0,
  clear: () => {},
  key: () => '',
};

export class LocalStorage<Item extends Record<string, unknown>> {
  defaultContent: Item;

  key: string;

  storage: Storage;

  constructor({
    key,
    type,
    defaultContent,
  }: {
    key: string;
    type: 'local' | 'session';
    defaultContent: Item;
  }) {
    this.key = key;
    if (isUnitTesting || !isBrowser) {
      this.storage = storageMock;
    } else {
      this.storage =
        type === 'local' ? window.localStorage : window.sessionStorage;
    }

    this.defaultContent = defaultContent;
  }

  setItem(item: Item): void {
    this.storage.setItem(this.key, JSON.stringify(item));
  }

  getItem(): Item {
    try {
      const itemString = this.storage.getItem(this.key);
      if (!itemString) {
        this.setItem(this.defaultContent);
        return this.defaultContent;
      }
      return JSON.parse(itemString);
    } catch (e) {
      this.setItem(this.defaultContent);
      return this.defaultContent;
    }
  }

  hasItem(): boolean {
    const itemString = this.storage.getItem(this.key);
    return !!itemString;
  }

  removeItem(): void {
    this.storage.removeItem(this.key);
  }

  modifyItem(modifier: (item: Item) => Item): void {
    const item = this.getItem();
    this.setItem(modifier(item));
  }
}
