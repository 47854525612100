import { KsChange } from '@/types';
import { hasuraGql as gql } from '@/services/hasura-client';
import { now } from '@knapsack/utils';
import { trackEvent } from '@/utils/analytics';

// Graphql client for the app-db (Hasura)
export const {
  UpdateSiteInstanceStatus,
  getBranchNameForInstance,
  updateInstanceTitle: updateInstanceTitleGql,
  GetBranchTitle,
} = gql;

export async function saveDataChanges({
  changes,
  userId,
  instanceId,
}: {
  changes: KsChange[];
  userId: string;
  instanceId: string;
}): Promise<void> {
  const results = await gql.SaveDataChange({
    instanceId,
    now: now(),
    dataChange: {
      userId,
      instanceId,
      patches: changes.flatMap(({ patches }) => patches),
      inversePatches: changes.flatMap(({ inversePatches }) => inversePatches),
    },
  });
  const dataChangeId = results?.dataChange?.id;
  if (!dataChangeId) {
    throw new Error(`Could not save data changes`);
  }
}

export async function updateInstanceTitle({
  instanceId,
  title,
}: {
  instanceId: string;
  title: string;
}): Promise<{ id: string; title: string }> {
  const {
    update_siteInstances_by_pk: { id },
  } = await updateInstanceTitleGql({
    instanceId,
    newTitle: title,
  });
  if (!id) {
    throw new Error(`Could not update instance title`);
  }
  trackEvent({
    type: 'Branch Renamed',
    metadata: {
      instanceId,
      title,
    },
  });
  return {
    id,
    title,
  };
}

export async function getBranchTitle({
  instanceId,
}: {
  instanceId: string;
}): Promise<{ title: string }> {
  const {
    siteInstances_by_pk: { title },
  } = await GetBranchTitle({ instanceId });
  return {
    title,
  };
}
