import flatten from 'lodash/flatten';
import { KnapsackNavItem } from '@knapsack/types';
import { isRemoteUrl, slugify } from '@knapsack/utils';
import { secondaryNavRootKey } from './constants';
import { makeId } from './make-id';

/**
 * Pull off the root items from nav array
 */
export const determineTopNav = (source: KnapsackNavItem[]): KnapsackNavItem[] =>
  source.filter((nav) => nav?.parentId === secondaryNavRootKey);

/**
 * Recursively follow parent ids to determine everything below a nav item
 * Note: this is not actually used currently
 */
export const determineSubNav = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem[] => {
  const filteredSubs = source.filter((item) => item.parentId === id);

  // We've hit "bottom", bail
  if (!filteredSubs.length) return filteredSubs;

  // Otherwise recursively dive deep and flatten all returned arrays
  return [
    ...filteredSubs,
    ...flatten(
      filteredSubs.map((item) => determineSubNav({ source, id: item.id })),
    ),
  ];
};

/**
 * Find current Nav Item by pathname
 */
export const getNavItemByPathName = ({
  source,
  pathname,
}: {
  source: KnapsackNavItem[];
  pathname: string;
}): KnapsackNavItem =>
  source.find((item) => {
    // Ensure we don't test against empty paths, and then match
    if (!item?.path) return false;
    return pathname.endsWith(item?.path);
  });

/**
 * Find current Nav Item by ID
 */
export const getCurrentNavItem = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id?: string;
}): KnapsackNavItem => {
  if (!id) return null;
  const result = source.find((item) => item.id === id);

  if (!result) {
    throw Error(`cannot find a nav item with an id of ${id}`);
  } else {
    return result;
  }
};

/**
 * From any nav id, determine what the current topmost nav item is.
 */
export const determineTopNavItem = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id?: string;
}): KnapsackNavItem | null => {
  if (!id) return null;
  const current = getCurrentNavItem({ source, id });
  if (!current) return null;
  if (current.parentId === secondaryNavRootKey) return current;

  const ascend = (lookup: KnapsackNavItem): KnapsackNavItem => {
    const step = source.find((item) => item.id === lookup.parentId);
    if (!step) return null;
    return step.parentId === secondaryNavRootKey ? step : ascend(step);
  };

  return ascend(current);
};

/**
 * Find the first parent above a nav item
 */
export const determineParent = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem | null => {
  const current = getCurrentNavItem({ source, id });

  const parent = source.find((item) => current.parentId === item.id);

  return parent ?? null;
};

/**
 * Get all siblings at items same level (if any)
 */
export const determineSiblings = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem[] => {
  const parent = determineParent({ source, id });
  if (!parent) return null;

  return source.filter((item) => item.parentId === parent.id);
};

/**
 * Returns closest sibling to the left *that has a path*
 */
export const determineClosestFirstLinkableSibling = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem | null => {
  // Get siblings **that have actual paths** (we can't link to groups)
  const siblings = determineSiblings({ source, id }).filter(
    (item) => !!item.path,
  );

  // If array is empty or only includes item, there are no siblings
  if (siblings.length === 0 || siblings.length === 1) return null;

  // Now, find index of item, ensure we return first left if index >= 1
  const itemIndex = siblings.findIndex((item) => item.id === id);
  const firstSiblingIndex = itemIndex === 0 ? itemIndex + 1 : itemIndex - 1;

  return siblings[firstSiblingIndex];
};

export const getIdealSibling = ({
  siblings,
  id,
}: {
  siblings: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem => {
  if (!siblings.length) {
    throw Error('cannot find ideal sibling to redirect to');
  }

  if (!siblings.find((item) => item.id === id)) {
    return siblings[0];
  }

  const itemIndex = siblings.findIndex((item) => item.id === id);
  const firstSiblingIndex = itemIndex === 0 ? itemIndex + 1 : itemIndex - 1;
  return siblings[firstSiblingIndex];
};

/**
 * Returns closest sibling to the left *that has a path*
 */
export const determineClosestFirstLinkableSiblingOrParent = ({
  source,
  id,
}: {
  source: KnapsackNavItem[];
  id: string;
}): KnapsackNavItem | null => {
  let navItemId = id;
  let navItem: KnapsackNavItem;

  while (navItem?.parentId !== 'root') {
    if (!navItemId) {
      throw Error(`missing navItemId ${navItemId}`);
    }
    navItem = getCurrentNavItem({ source, id: navItemId });

    // return early if the current nav item isn't the original + has a path
    if (navItem?.path !== '' && navItem?.id !== id) {
      return navItem;
    }

    const siblings = determineSiblings({
      source,
      id: navItem?.id,
    });

    // Filter out siblings that don't have paths or are remote urls
    const linkableSiblings = siblings
      ?.filter((item) => item.path !== '')
      .filter((item) => !isRemoteUrl(item.path));

    if (linkableSiblings?.length) {
      const result = getIdealSibling({
        siblings: linkableSiblings,
        id: navItemId,
      });
      if (result) {
        return result;
      }
    }
    if (navItem?.parentId === null) {
      return null;
    }
    navItemId = navItem.parentId;
  }
  return null;
};

export const getPageId = ({
  text,
  navIds,
  currentId,
}: {
  text: string;
  navIds: Array<string>;
  currentId?: string;
}) => {
  let id = slugify({ string: text });

  if (currentId && currentId.toLowerCase() === id) return currentId;
  /**
   * Check against navIds to account for groups, pages, and patterns
   */
  const alreadyExists = navIds.find((navId) => navId.toLowerCase() === id);
  if (alreadyExists || id === 'homepage') {
    id = slugify({ string: `${text} ${makeId({ isShort: true })}` });
  }
  return id;
};
