import { Draft } from 'immer';
import { KsAppClientDataNoMeta } from '@/types';
import { trackEvent } from '@/utils/analytics';
import { KsFile } from '@knapsack/types';

import {
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
} from './shared.xstate';
import { getBlocksByType } from './utils/utils.xstate';

export type FilesState = KsAppClientDataNoMeta['filesState'];

export const filesInitialState: FilesState = {
  files: {},
};

export type FilesActions =
  | SetAppClientData
  | ResetAppClientData
  | {
      type: 'knapsack/files/ADD';
      file: KsFile;
    }
  | {
      type: 'knapsack/files/DELETE';
      blockId: string;
      fileId: string;
    }
  | {
      type: 'knapsack/files/UPDATE';
      fileId: string;
      file: Partial<Omit<KsFile, 'id'>>;
    };

export default function reducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: FilesActions,
): FilesState {
  switch (action.type) {
    case SET_APP_CLIENT_DATA:
      data.filesState = action.payload.filesState || filesInitialState;
      return;
    case RESET_APP_CLIENT_DATA:
      data.filesState = filesInitialState;
      return;
    case 'knapsack/files/ADD': {
      data.filesState.files[action.file.id] = action.file;
      const { title, size, mimetype } = action.file;
      trackEvent({
        type: 'File Added',
        metadata: { title, size, mimetype },
      });
      return;
    }
    case 'knapsack/files/DELETE': {
      const filesListBlocks = getBlocksByType({
        blockType: 'files-list',
        data,
      });
      const isFileUsed = filesListBlocks.some(
        (b) => b.id !== action.blockId && b.data.files.includes(action.fileId),
      );
      if (!isFileUsed) {
        delete data.filesState.files[action.fileId];
        trackEvent({
          type: 'File Deleted',
        });
      }
      return;
    }
    case 'knapsack/files/UPDATE': {
      data.filesState.files[action.fileId] = {
        ...data.filesState.files[action.fileId],
        ...action.file,
      };
      const { title, size, mimetype } = action.file;
      trackEvent({
        type: 'File Edited',
        metadata: { title, size, mimetype },
      });
    }
  }
}
