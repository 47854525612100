import { PayloadSender, State, Typestate } from 'xstate';
import type { Role } from '@knapsack/types';
import { ToastProps, createToast } from '@knapsack/toby';
import {
  getXstateUtils,
  MachineStateSchemaPaths,
} from '@/core/xstate/xstate.utils';
import type { KsAppClientDataNoMeta } from '@/types';
import { LaunchDarklyFeatureFlags } from '@/utils/launch-darkly-feature-flags';
import { LoadPluginResult } from '@/domains/plugins';
import {
  planNumbers,
  type Plans_Enum,
  type SiteStatuses_Enum,
  type SiteInstanceStatuses_Enum,
} from '@knapsack/hasura-gql-client';
import type { SetAppClientData } from '../app-client-data/reducers/all-event-creators.xstate';
import type { KscUser } from '../../../../types/db.types';
import { SiteLoadResults } from './sub-states/site.xstate-types';

/**
 * These State Machine IDs are invoked from within the App machine
 * Most are invoked right when the App machine starts and are global always-on machines. These are the the sub-machines that get custom React hooks made and exported out.
 * Others are invoked in certain states.
 * These IDs are used for sending events directly to them.
 */
export const APP_SUB_MACHINE_IDS = {
  /** global machine */
  ui: 'ui',
  /** global machine */
  appClientData: 'appClientData',
  localDev: 'localDev',
};

type PlansFeatureFlags = {
  enablePrototyping: boolean;
  enableTheming: boolean;
  figmaVars: boolean;
};

export function removePlansFlags<
  F extends Partial<LaunchDarklyFeatureFlags & PlansFeatureFlags>,
>({
  /* eslint-disable unused-imports/no-unused-vars */
  // we remove some flags here b/c they are set using plans elsewhere
  // we have extra safeguards b/c they will still be in Launch Darkly at first, but we'll remove them eventually
  enablePrototyping,
  enableTheming,
  figmaVars,
  /* eslint-enable unused-imports/no-unused-vars */
  ...launchDarklyFlags
}: F): Omit<F, keyof PlansFeatureFlags> {
  return launchDarklyFlags;
}

export function getPlansFlags(planId: Plans_Enum): PlansFeatureFlags {
  const plan = planNumbers[planId] || 0;
  const gt20 = plan > planNumbers.L20;
  return {
    enablePrototyping: gt20,
    enableTheming: gt20,
    figmaVars: gt20,
  };
}

export interface AppContext {
  site?: SiteLoadResults['site'];
  pluginsLoadResults?: LoadPluginResult[];
  user?: {
    userId: string;
    email: string;
    emailVerified: boolean;
    info?: KscUser;
    getSiteRole: (siteId: string) => Role;
    membershipSiteIds: string[];
    isSuperAdmin: boolean;
  };
  userError?: {
    title: string;
    details?: string;
    errorCode?: import('../../../../firebase').UserErrorCodes;
  };
  featureFlags: {
    isThemeSwitcherEnabled?: boolean;
    isBreadcrumbsEnabled?: boolean;
    isHtmlRendererEnabled?: boolean;
    isSiteCustomInstanceSwitcherEnabled?: boolean;
    isSiteInstanceSwitcherEnabled?: boolean;
    isSiteSwitcherEnabled?: boolean;
    isAppHomeEnabled?: boolean;
  } & PlansFeatureFlags &
    LaunchDarklyFeatureFlags;
}

/* eslint-disable @typescript-eslint/ban-types */
export interface AppStateSchema {
  states: {
    site: {
      states: {
        unloaded: {};
        loaded: {
          states: {
            instance: {
              states: {
                loaded: {};
                deleting: {};
              };
            };
            env: {
              states: {
                unknown: {};
                switching: {};
                production: {};
                development: {};
                preview: {};
              };
            };
            appClient: {
              states: {
                unknown: {};
                needsAuth: {};
                noAccess: {};
                viewable: {
                  states: {
                    uneditable: {};
                    preview: {};
                    error: {};
                    editable: {};
                  };
                };
              };
            };

            plugins: {
              states: {
                loading: {};
                loaded: {};
                partiallyLoaded: {};
                error: {};
              };
            };
          };
        };
      };
    };
    user: {
      states: {
        unknown: {};
        authError: {};
        loggedOut: {};
        loggedIn: {
          states: {
            loadingDetails: {};
            loadingError: {};
            loaded: {
              states: {
                userInfo: {
                  states: {
                    idle: {};
                    updating: {};
                  };
                };
              };
            };
          };
        };
      };
    };
    usersAndSite: {
      states: {
        unknown: {
          states: {
            noUser: {};
            withUser: {};
          };
        };
        siteLoaded: {
          states: {
            unknownUser: {};
            anonUser: {};
            loggedInUser: {
              states: {
                someInfo: {};
                allInfo: {};
              };
            };
          };
        };
      };
    };
  };
}
/* eslint-enable @typescript-eslint/ban-types */

export type UserTopStates = AppStateSchema['states']['user']['states'];
export type SiteTopStates = AppStateSchema['states']['site']['states'];

export type SharedEvents =
  // Sometimes we need to return an event that does nothing
  | { type: 'noop' }
  | {
      type: 'appClientData.changed';
      appClientData: KsAppClientDataNoMeta;
    }
  | SetAppClientData
  | {
      type: 'user.authed';
      user: AppContext['user'];
    }
  | { type: 'user.authError'; errorMsg: string }
  | { type: 'user.clearError' }
  | { type: 'user.unload' }
  | { type: 'user.clearError' }
  | {
      type: 'userAndSite.haveBoth';
      userId: string;
      roleForSite: Role;
      siteId: string;
    }
  | {
      type: `site.instanceStatusChanged`;
      instanceStatus: SiteInstanceStatuses_Enum;
    }
  // Handle when tokens are deleted by accepting a hard value to set config to
  | {
      type: 'tokens.tokenDelete';
      tokenInfo: { name: string; originalValue: string };
    }
  // Handle when tokens are renamed by changing old name to new name
  | {
      type: 'tokens.tokenRename';
      tokenInfo: { oldName: string; newName: string };
    };

export type AppEvents =
  | {
      type: 'site.loadInstance';
      data: SiteLoadResults;
    }
  | { type: 'site.deleteCurrentInstance' }
  | { type: 'site.statusChanged'; status: `${SiteStatuses_Enum}` }
  | { type: 'site.switchInstance'; instanceId: 'latest' | string }
  | { type: 'site.switchEnvUrl'; envUrl: string }
  | { type: 'site.createInstance' }
  | { type: 'xstate.error'; data: Error }
  | { type: 'site.previewOn' }
  | { type: 'site.previewOff' }
  | { type: 'user.signOut' }
  | { type: 'user.load'; userId: string; email: string }
  | {
      type: 'user.updateInfo';
      info: Partial<AppContext['user']['info']>;
      email?: string;
      successMsg?: string;
    }
  | {
      type: 'user.updateInfo.done';
      info: Partial<AppContext['user']['info']>;
      successMsg?: string;
    }
  | {
      type: 'user.updateInfo.fail';
      msg: string;
    }
  | { type: 'user.infoChanged'; info: AppContext['user']['info'] }
  | { type: 'user.error'; msg: string; details?: string; error?: Error }
  | { type: 'site.reloadPlugins' }
  | { type: 'app.log'; msg: unknown }
  | { type: 'app.sendUserMsg'; msg: ToastProps }
  | {
      type: 'featureFlags.changed';
      flags: Partial<AppContext['featureFlags']>;
    }
  | SharedEvents;

/** All `AppEvents` whose `type` starts with `user` followed by other strings */
export type AppUserEvents = Extract<AppEvents, { type: `user.${string}` }>;

/**
 * All state values as strings in dot notation, i.e. `'user.loggedIn' | 'user.loggedIn.loadingDetails'`
 * These strings are passed to `state.matches('user.loggedIn')` which returns a `boolean`
 * If `state.matches()` is not typed correctly, use this type:
 * @example
 * const isLoggedIn = state.matches<AppStateValues>('user.loggedIn');
 */
export type AppStateValues = MachineStateSchemaPaths<AppStateSchema['states']>;
export interface AppTypestates extends Typestate<AppContext> {
  context: AppContext;
  value: AppStateValues;
}

export type AppState = State<
  AppContext,
  AppEvents,
  AppStateSchema,
  AppTypestates
>;

export type AppSender = PayloadSender<AppEvents>;

export const {
  createAction,
  createInvokablePromise,
  createXstateHooks: createAppXstateHooks,
} = getXstateUtils<AppContext, AppEvents, AppTypestates, AppStateSchema>();

export const sendUserMessage = createToast;
