import {
  Machine,
  Typestate,
  Sender,
  SpawnedActorRef,
  Interpreter,
} from 'xstate';
import { createToast, dismissToast } from '@knapsack/toby';
import { MachineStateSchemaPaths } from '@/core/xstate/xstate.utils';
import { WebSocketMessages } from '@knapsack/types';
import { isCypress } from '@/utils/constants';
import { checkAppClientUrl } from '@/services/app-client.client';
import {
  AppContext,
  sendUserMessage,
  SharedEvents,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';

/* eslint-disable @typescript-eslint/ban-types */
export interface LocalDevState {
  states: {
    disconnected: {};
    connected: {};
    givingUp: {};
  };
}
/* eslint-enable @typescript-eslint/ban-types */

export interface LocalDevCtx {
  site?: AppContext['site'];
}

export type LocalDevEvents =
  | { type: `disconnected` }
  | { type: `connected` }
  | { type: 'switchEnvBackToProd' }
  | SharedEvents;

export interface LocalDevTypestates extends Typestate<LocalDevCtx> {
  context: LocalDevCtx;
  value: MachineStateSchemaPaths<LocalDevState['states']>;
}

export type LocalDevSpawnedActorRef = SpawnedActorRef<
  LocalDevEvents,
  SharedEvents
>;

export type LocalDevInterpreter = Interpreter<
  LocalDevCtx,
  LocalDevState,
  LocalDevEvents,
  LocalDevTypestates
>;

export const localDevMachine = Machine<
  LocalDevCtx,
  LocalDevState,
  LocalDevEvents
>({
  id: APP_SUB_MACHINE_IDS.localDev,
  strict: true,
  initial: 'connected',
  states: {
    disconnected: {
      on: {
        connected: 'connected',
        switchEnvBackToProd: 'givingUp',
      },
      // entering this state will start the connection checker below
      // which will repeatedly check the app client url
      invoke: {
        id: 'connectionChecker',
        src:
          ({ site }) =>
          (sendEvent) => {
            const { url: appClientUrl } = site.env;
            const { toastId } = createToast({
              type: 'error',
              autoClose: false,
              title: 'Server Offline',
              message:
                "Your local dev server appears to be offline. We'll keep trying to reconnect...",
              action:
                site.contentSrc.type === 'cloud-authoring'
                  ? {
                      label: 'Switch back to Prod',
                      onTrigger: () => {
                        sendEvent({
                          type: 'switchEnvBackToProd',
                        });
                      },
                    }
                  : null,
            });
            const intervalMs = 5_000;
            // 5 minutes
            const totalTimeToTry = 5 * 60_000;
            const totalAttemptsToTry = totalTimeToTry / intervalMs;
            let attempts = 0;

            const intervalId = setInterval(async () => {
              const { ok } = await checkAppClientUrl(appClientUrl);
              if (ok) {
                dismissToast(toastId);

                sendUserMessage({
                  type: 'success',
                  message: 'Reconnected to local dev server!',
                  autoClose: 3000,
                });
                // this event will cause a transition out of this state, which will
                // fire `clearInterval` in the cleanuup below
                sendEvent({ type: 'connected' });
                return;
              }

              attempts += 1;
              if (attempts < totalAttemptsToTry) {
                // less than 5 minutes, keep trying
                return;
              }
              // if we've tried for 5 minutes, give up
              if (site.contentSrc.type === 'cloud-authoring') {
                sendEvent({
                  type: 'switchEnvBackToProd',
                });
              } else {
                dismissToast(toastId);
                sendUserMessage({
                  type: 'error',
                  autoClose: false,
                  title: 'Server Offline',
                  message:
                    'Your local dev server appears to be offline. Reload the page to try again.',
                  action: {
                    label: 'Reload',
                    onTrigger: () => window.location.reload(),
                  },
                });
                // kill the interval since we've given up
                clearInterval(intervalId);
              }
            }, intervalMs);

            return () => {
              dismissToast(toastId);
              clearInterval(intervalId);
            };
          },
      },
    },
    connected: {
      invoke: {
        id: 'Local WebSocket',
        src: (ctx) => (sendEvent: Sender<LocalDevEvents>) => {
          if (isCypress()) return;
          if (ctx.site?.env?.type !== 'development') return;
          if (!ctx.site.env.websocketsEndpoint) return;
          const socket = new window.WebSocket(ctx.site.env.websocketsEndpoint);
          socket.addEventListener('message', ({ data }) => {
            const _theMsg: WebSocketMessages = JSON.parse(data ?? '{}');
            // we used to handle websocket messages here, but now the only one we
            // use is `RENDERER_CLIENT_RELOAD` which is handled in the renderer client (see `@knapsack/renderer-client`)
            // that code is ran inside the `<iframe>` itself and triggers a page reload there
            // the reason this websocket codes is being kept is for 2 reasons:
            // 1. when the websocket is closed, we transition to the `disconnected` state
            //    and we show a toast to the user that the server is offline
            // 2. it's set up for future use if we want to use websocket events again
          });

          socket.addEventListener('error', (error) => {
            console.error(error);
            sendEvent({
              type: 'disconnected',
            });
          });

          socket.addEventListener('close', () => {
            sendEvent({
              type: 'disconnected',
            });
          });

          return () => {
            socket?.close?.(1000, 'unmounting');
          };
        },
        onError: {
          target: 'disconnected',
        },
      },
      on: {
        disconnected: 'disconnected',
      },
    },
    givingUp: {
      type: 'final',
    },
  },
});
