import { LocalStorage } from '@/utils/local-storage';

export const userLocalStorage = new LocalStorage<{
  redirectUrl: string;
}>({
  defaultContent: {
    redirectUrl: '',
  },
  key: 'app-ui-storage',
  type: 'local',
});
