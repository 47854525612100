import { Draft } from 'immer';
import type {
  KnapsackAssetSetData,
  KsAppClientDataNoMeta,
} from '@knapsack/types';
import {
  Action,
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
} from './shared.xstate';

export type AssetSetState = KsAppClientDataNoMeta['assetSetsState'];

const UPDATE = 'knapsack/asset-sets/UPDATE';
const ADD = 'knapsack/asset-sets/ADD';
const REMOVE = 'knapsack/asset-sets/REMOVE';

export const assetSetsInitialState: AssetSetState = {};

export interface UpdateAssetSetAction extends Action {
  type: typeof UPDATE;
  payload: KnapsackAssetSetData;
}

export function updateAssetSet(
  assetSet: KnapsackAssetSetData,
): UpdateAssetSetAction {
  return {
    type: UPDATE,
    payload: assetSet,
  };
}

export interface AddAssetSetAction extends Action {
  type: typeof ADD;
  payload: KnapsackAssetSetData;
}

export function addAssetSet(assetSet: KnapsackAssetSetData): AddAssetSetAction {
  return {
    type: ADD,
    payload: assetSet,
  };
}

export interface RemoveAssetSetAction extends Action {
  type: typeof REMOVE;
  payload: {
    id: string;
  };
}

export function removeAssetSet(id: string): RemoveAssetSetAction {
  return {
    type: REMOVE,
    payload: {
      id,
    },
  };
}

export type AssetSetsActions =
  | RemoveAssetSetAction
  | AddAssetSetAction
  | UpdateAssetSetAction
  | SetAppClientData
  | ResetAppClientData;

export default (
  data: Draft<KsAppClientDataNoMeta>,
  action: AssetSetsActions,
): AssetSetState => {
  // providing some backwards compatibility vars for when this was Redux style: return new state shape for this state property
  // each `case` often used Immer's produce for that one `case`, now Immer is called higher up
  const state = data.assetSetsState;
  const draft = data.assetSetsState;
  switch (action.type) {
    case SET_APP_CLIENT_DATA:
      data.assetSetsState = action.payload.assetSetsState;
      return;
    case RESET_APP_CLIENT_DATA:
      data.assetSetsState = assetSetsInitialState;
      return;
    case UPDATE:
      draft.allAssetSets[action.payload.id] = action.payload;
      return;
    case REMOVE:
      // @todo remove anything that uses this
      delete draft.allAssetSets[action.payload.id];
      return;
    case ADD:
      draft.allAssetSets[action.payload.id] = action.payload;
  }
};
