export const WS_EVENTS = {
  PATTERN_TEMPLATE_CHANGED: 'PATTERN_TEMPLATE_CHANGED',
  PATTERN_ASSET_CHANGED: 'PATTERN_ASSET_CHANGED',
  DESIGN_TOKENS_CHANGED: 'DESIGN_TOKENS_CHANGED',
  RENDERER_CLIENT_RELOAD: 'RENDERER_CLIENT_RELOAD',
  APP_CLIENT_DATA_CHANGED: 'APP_CLIENT_DATA_CHANGED',
};

export type WebSocketEvent = keyof typeof WS_EVENTS;

export interface WebSocketMessage {
  event: string;
  data: Record<string, unknown>;
}

export interface DesignTokensChangedData extends WebSocketMessage {
  event: typeof WS_EVENTS.DESIGN_TOKENS_CHANGED;
  data: Record<string, unknown>;
}

export interface PatternChangedData extends WebSocketMessage {
  event: typeof WS_EVENTS.PATTERN_TEMPLATE_CHANGED;
  data: {
    /**
     * Absolute path to template on server that was just edited
     */
    path: string;
  };
}

export interface AssetChangedData extends WebSocketMessage {
  event: typeof WS_EVENTS.PATTERN_ASSET_CHANGED;
  data: {
    /**
     * Absolute path to asset file (CSS/JS) on server that was just edited
     */
    path: string;
  };
}

export interface RendererClientReloadMessage extends WebSocketMessage {
  event: typeof WS_EVENTS.RENDERER_CLIENT_RELOAD;
  data: {
    // @todo add patternId etc for more details
  };
}

export interface AppClientDataChanged extends WebSocketMessage {
  event: typeof WS_EVENTS.PATTERN_TEMPLATE_CHANGED;
  data: Record<string, unknown>;
}

export type WebSocketMessages =
  | PatternChangedData
  | AssetChangedData
  | DesignTokensChangedData
  | RendererClientReloadMessage;
