import { StateNodeConfig, send, Action, forwardTo } from 'xstate';
import { PromiseResult } from '@/utils/type-utils';
import { canRoleView } from '@knapsack/core';
import { assign } from '@xstate/immer';
import {
  trackEvent,
  updateAnalyticsSite,
  removeAnalyticsSite,
} from '@/utils/analytics';
import { featureFlags } from '@/utils/feature-flags';
import { appClientMinimumVersion } from '@/utils/constants';
import { appApiGql } from '@/services/app-api-client';
import { swapInstanceIdInUrl } from '@/domains/branches/utils';
import { resetPlugins, loadPlugins } from '@/domains/plugins';
import semverLessThan from 'semver/functions/lt';
import {
  removeActiveEnvUrl,
  setActiveEnvUrl,
} from '@/core/env-and-content-src/env-url-storage';
import { getEnvDetails } from '@/core/env-and-content-src/utils';
import { Env } from '@/core/env-and-content-src/env-and-content-src.types';
import { knapsackGlobal } from '@/global';
import * as appGuards from '../app.xstate-guards';
import type { AppClientDataEvents } from '../../app-client-data';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  sendUserMessage,
  getPlansFlags,
  AppStateValues,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';
import { LocalDevCtx, localDevMachine } from './site.localDev.xstate';

const envLoadActions: Action<AppContext, AppEvents>[] = [
  send((): AppEvents => ({ type: 'site.reloadPlugins' })),
  send(
    (ctx): AppClientDataEvents => ({
      type: 'env.switch',
      env: ctx.site.env,
    }),
    { to: APP_SUB_MACHINE_IDS.appClientData },
  ),
  (ctx) => {
    if (ctx.site.env.type === 'production') {
      removeActiveEnvUrl({ siteId: ctx.site.meta.siteId });
    } else {
      setActiveEnvUrl({
        siteId: ctx.site.meta.siteId,
        appClientUrl: ctx.site.env.url,
      });
    }
  },
];

export const siteStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['site'],
  AppEvents
> = {
  id: 'site',
  strict: true,
  initial: 'unloaded',
  on: {
    'site.loadInstance': {
      target: '.loaded',
      actions: [
        send(
          (): AppClientDataEvents => ({
            // only needed for switching instances, will be no-op if not switching
            type: 'appClientData.prepForInstanceSwitch',
          }),
          { to: APP_SUB_MACHINE_IDS.appClientData },
        ),
        send(
          (
            _ctx,
            { data: { site, appClientData, tokenData, tokenStyles } },
          ): AppClientDataEvents => {
            return {
              type: 'knapsack/SET_APP_CLIENT_DATA',
              payload: appClientData,
              site,
              tokenData,
              tokenStyles,
            };
          },
          { to: APP_SUB_MACHINE_IDS.appClientData },
        ),
        assign((ctx, { data }) => {
          ctx.site = data.site;
          ctx.featureFlags = {
            ...ctx.featureFlags,
            ...getPlansFlags(data.site.meta.plan),
          };
        }),
      ],
    },
    'site.switchInstance': {
      actions: (ctx, { instanceId }) => {
        if (ctx.site.contentSrc.type !== 'cloud-authoring') {
          throw new Error(
            'Cannot switch instance when contentSrc is not cloud-authoring',
          );
        }
        const { newInstanceUrl } = swapInstanceIdInUrl({
          newInstanceId: instanceId,
        });
        // the below code will use router
        knapsackGlobal.goToUrl(newInstanceUrl);
        trackEvent({ type: 'Branch Switched' });
      },
    },
  },
  states: {
    unloaded: {},
    loaded: {
      type: 'parallel',
      entry: [
        assign((ctx) => {
          if (featureFlags.forceSiteToPrivate) {
            ctx.site.meta.isPrivate = true;
          }
        }),
      ],
      exit: [
        assign((ctx) => {
          ctx.site = null;
        }),
      ],
      on: {
        'site.statusChanged': {
          actions: assign((ctx, { status }) => {
            ctx.site.meta.status = status;
          }),
        },
        'site.instanceStatusChanged': {
          actions: [
            assign((ctx, { instanceStatus }) => {
              if (
                ctx.site?.contentSrc?.type === 'cloud-authoring' &&
                ctx.site.contentSrc.instance.type === 'branch'
              ) {
                ctx.site.contentSrc.instance.instanceStatus = instanceStatus;
              }
            }),
            forwardTo(APP_SUB_MACHINE_IDS.appClientData),
          ],
        },
      },
      states: {
        instance: {
          initial: 'loaded',
          states: {
            loaded: {
              on: {
                'site.deleteCurrentInstance': {
                  target: 'deleting',
                  cond: function isBranchInstance(ctx) {
                    if (ctx.site.contentSrc.type !== 'cloud-authoring') {
                      throw new Error(
                        'Cannot delete instance when contentSrc is not cloud-authoring',
                      );
                    }
                    return ctx.site.contentSrc.instance.type === 'branch';
                  },
                },
              },
            },
            deleting: {
              invoke: createInvokablePromise<{ msg: string }>({
                id: 'delete-instance',
                src: async (ctx) => {
                  if (ctx.site.contentSrc.type !== 'cloud-authoring') {
                    throw new Error(
                      'Cannot delete instance when contentSrc is not cloud-authoring',
                    );
                  }
                  if (ctx.site.contentSrc.instance.type === 'latest') {
                    throw new Error(`Cannot delete the "latest" instance`);
                  }
                  const { instanceId, gitBranch } =
                    ctx.site.contentSrc.instance;
                  const { siteId } = ctx.site.meta;

                  const response = await appApiGql.deleteBranch({
                    siteId,
                    branch: gitBranch,
                  });

                  const { affectedInstances, branchDeletedAtRemote, msg } =
                    response.deleteBranch;

                  // Did we actually delete the instance we're on? Note: this
                  // only takes into account instance at the KS database. The
                  // git branch may or may not have been deleted remotely. See
                  // `msg` contents for more detail.
                  const didDeleteThisInstance = affectedInstances
                    .map(({ id }) => id)
                    .includes(instanceId);
                  // If we didn't actually delete our instance, let everyone know
                  if (!didDeleteThisInstance) {
                    throw new Error(`Did not properly delete "${instanceId}"`);
                  }

                  return {
                    msg,
                  };
                },
                onDoneTarget: 'loaded',
                onErrorTarget: 'loaded',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, { data: error }) {
                      console.error(error);
                      sendUserMessage({
                        type: 'error',
                        title: 'Error Deleting Instance',
                        message: error.message,
                      });
                    },
                  },
                ],
                onDoneActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      const { msg } = event.data;

                      sendUserMessage({
                        type: 'success',
                        message: `${msg} Switching to latest.`,
                      });
                    },
                  },
                  send(
                    (ctx, event): AppEvents => ({
                      type: 'site.switchInstance',
                      instanceId: 'latest',
                    }),
                    {
                      delay: 100,
                    },
                  ),
                  () => {
                    trackEvent({
                      type: 'Branch Deleted',
                    });
                  },
                ],
              }),
            },
          },
        },
        env: {
          initial: 'unknown',
          on: {
            'site.switchEnvUrl': '.switching',
          },
          states: {
            unknown: {
              always: [
                {
                  cond: (ctx) => ctx.site?.env?.type === 'production',
                  target: 'production',
                },
                {
                  cond: (ctx) => ctx.site?.env?.type === 'development',
                  target: 'development',
                },
                {
                  cond: (ctx) => ctx.site?.env?.type === 'preview',
                  target: 'preview',
                },
              ],
            },
            switching: {
              invoke: createInvokablePromise<Env>({
                id: 'switch-env',
                src: async (ctx, event) => {
                  if (event.type !== 'site.switchEnvUrl') {
                    throw new Error(
                      `Event type "${event.type}" is not supported`,
                    );
                  }
                  const env = await getEnvDetails({
                    envUrl: event.envUrl,
                    prodEnvUrl: ctx.site.meta.prodEnvUrl,
                  });
                  if (env instanceof Error) throw env;
                  if (
                    env.appClientMeta.knapsackCloudSiteId !==
                    ctx.site.meta.siteId
                  ) {
                    throw new Error(
                      `Env ${event.envUrl} does not match site ${ctx.site.meta.siteId}`,
                    );
                  }
                  return env;
                },
                onDone: {
                  target: 'unknown', // will handle transition target
                  actions: assign((ctx, { data: env }) => {
                    ctx.site.env = env;
                  }),
                },
                onErrorTarget: 'unknown', // will restore to previous env
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, { data: error }) {
                      console.error(error);
                      sendUserMessage({
                        type: 'error',
                        title: 'Error Switching Env',
                        message: error.message,
                      });
                    },
                  },
                ],
              }),
            },
            production: {
              entry: envLoadActions,
            },
            preview: {
              entry: envLoadActions,
            },
            development: {
              entry: envLoadActions,
              invoke: {
                id: localDevMachine.id,
                src: localDevMachine,
                data: ({ site }): LocalDevCtx => ({ site }),
                onDone: {
                  actions: send(
                    (ctx): AppEvents => ({
                      type: 'site.switchEnvUrl',
                      envUrl: ctx.site.meta.prodEnvUrl,
                    }),
                  ),
                },
              },
            },
          },
        },
        appClient: {
          initial: 'unknown',
          // Logging in/out takes us to the switchboard to decide what is next
          on: {
            'user.unload': 'appClient.unknown',
          },
          states: {
            // Starting point before deciding page is viewable or not. Consider
            // this a "switchboard" of states.
            unknown: {
              always: [
                // 1. Site is public
                // 2. Site is private, user is logged in, and role can view
                {
                  cond: appGuards.canUserView,
                  target: 'viewable',
                },
                // If we made it here, site is private and user is logged out
                {
                  target: 'needsAuth',
                  cond: function isLoggedOut(ctx, event, { state }) {
                    return state.matches<AppStateValues>('user.loggedOut');
                  },
                },
                // If we made it here, the things above failed. We are **not**
                // guaranteed to have a user!
                {
                  target: 'noAccess',
                  cond: function accessDenied(ctx, event, { state }) {
                    // Because we are here in the `unknown` state, we might not
                    // have a user yet. We can't block a user we don't know.
                    const isUserUnknown =
                      state.matches<AppStateValues>('user.unknown');
                    if (isUserUnknown) {
                      return false;
                    }
                    // We have a user and they don't have the right role
                    const roleForSite = ctx.user?.getSiteRole(
                      ctx.site?.meta.siteId,
                    );
                    const canRoleViewFlag = canRoleView(roleForSite);
                    return !canRoleViewFlag;
                  },
                },
              ],
            },
            needsAuth: {
              on: {
                'user.authed': 'unknown',
              },
            },
            noAccess: {},
            viewable: {
              initial: 'uneditable',
              // On entry into viewable, fire off event that can be forwarded
              // (from app.xstate) to other machines (like ui.xstate) so they
              // can know the viewable state
              entry: [
                ({
                  site: {
                    meta: { orgId, siteId },
                  },
                }) => {
                  updateAnalyticsSite({
                    siteId,
                    orgId,
                  });
                },
                {
                  type: 'sendUserAppClientUpdateMessage',
                  exec(ctx: AppContext, event: AppEvents) {
                    const appClientVersion =
                      ctx.site?.env?.appClientMeta?.ksVersions?.app;
                    if (appClientVersion) {
                      const isOlderAppClient = semverLessThan(
                        appClientVersion,
                        appClientMinimumVersion.desired,
                      );
                      if (isOlderAppClient) {
                        sendUserMessage({
                          type: 'warning',
                          message: `Please update your @knapsack NPM packages to "${appClientMinimumVersion.desired}" to use latest features. You're currently on "${appClientVersion}"`,
                        });
                      } else {
                        console.debug(
                          `App Client Version: ${appClientVersion}, UI's version: ${appClientMinimumVersion.desired}`,
                        );
                      }
                    }
                  },
                },
              ],
              exit: () => removeAnalyticsSite(),
              states: {
                uneditable: {
                  always: [
                    {
                      target: 'editable',
                      cond: appGuards.isEditable,
                    },
                  ],
                },
                error: {},
                preview: {
                  always: [
                    {
                      cond: function isUneditable(...args) {
                        return !appGuards.isEditable(...args);
                      },
                      target: 'uneditable',
                    },
                  ],
                  on: {
                    'site.previewOff': 'uneditable', // after the `cond` passes it'll go back to 'editable'
                  },
                },
                editable: {
                  always: [
                    {
                      cond: function isUneditable(...args) {
                        return !appGuards.isEditable(...args);
                      },
                      target: 'uneditable',
                    },
                  ],
                  on: {
                    'site.previewOn': 'preview',
                  },
                },
              },
            },
          },
        },
        plugins: {
          initial: 'loading',
          on: {
            'site.reloadPlugins': '.loading',
          },
          states: {
            loading: {
              invoke: createInvokablePromise<PromiseResult<typeof loadPlugins>>(
                {
                  id: 'loadPlugins',
                  src: async (ctx) => {
                    return loadPlugins({
                      urlBase: ctx.site?.env?.url,
                      pluginMetas: ctx.site?.env?.appClientMeta.plugins,
                    });
                  },
                  onErrorTarget: 'error',
                  onDone: [
                    {
                      target: 'loaded',
                      cond: (ctx, { data }) =>
                        data.filter(({ ok }) => !ok).length === 0,
                      actions: assign((ctx, event) => {
                        ctx.pluginsLoadResults = event.data;
                      }),
                    },
                    {
                      target: 'partiallyLoaded',
                      cond: (ctx, { data }) =>
                        data.filter(({ ok }) => ok).length > 0,
                      actions: assign((ctx, event) => {
                        ctx.pluginsLoadResults = event.data;
                      }),
                    },
                    {
                      target: 'error',
                    },
                  ],
                },
              ),
            },
            loaded: {
              exit: [
                {
                  type: 'unload plugins',
                  exec: () => resetPlugins(),
                },
              ],
            },
            partiallyLoaded: {
              exit: [
                {
                  type: 'unload plugins',
                  exec: () => resetPlugins(),
                },
              ],
            },
            error: {},
          },
        },
      },
    },
  },
};
