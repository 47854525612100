import { canRoleEdit, canRoleView } from '@knapsack/core';
import { isCypress } from '../../../../utils/constants';
import type { AppContext, AppEvents, AppState } from './app.xstate-utils';

type AppGuard = (
  ctx: AppContext,
  event: AppEvents,
  meta: { state: AppState },
) => boolean;

export const canUserView: AppGuard = (ctx) => {
  if (
    [
      'ks-demo-bootstrap',
      'ks-test',
      'ks-sandbox',
      // this isn't the actual Home Depot site, just a test site we set up for them originally and we want to ensure we can access it for font customizations
      'home-depot',
    ].includes(ctx.site?.meta.siteId) &&
    isCypress() === true
  ) {
    // allow Cypress to view & test those sites only
    return true;
  }
  if (!ctx.site?.meta.isPrivate) return true;
  const roleForSite = ctx.user?.getSiteRole(ctx.site?.meta.siteId);
  return canRoleView(roleForSite);
};

export const canUserEdit: AppGuard = (ctx, event, { state }) => {
  let result = false;
  const basics = state.matches('user.loggedIn') && state.matches('site.loaded');
  result = basics;
  if (basics) {
    const roleForSite = ctx.user?.getSiteRole(ctx.site?.meta.siteId);
    result = canRoleEdit(roleForSite);
  }
  return result;
};

export const isEditable: AppGuard = (ctx, event, meta) => {
  const { site } = ctx;
  if (!site) return false;
  const canEdit = canUserEdit(ctx, event, meta);

  switch (site.contentSrc.type) {
    case 'cloud-authoring': {
      switch (site.contentSrc.instance.type) {
        case 'latest':
          return false;
        case 'branch':
          return canEdit;
        default: {
          const _ex: never = site.contentSrc.instance;
          return false;
        }
      }
    }
    case 'current-env-server': {
      switch (site.env.type) {
        case 'preview':
        case 'production':
          return false;
        case 'development':
          return canEdit;
        default: {
          const _ex: never = site.env;
          return false;
        }
      }
    }
    default: {
      const _exhaustiveCheck: never = site.contentSrc;
      return false;
    }
  }
};
