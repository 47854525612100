export const tokenNameDisplayFormats = {
  default: 'Default',
  android: 'Android',
  cssVar: 'CSS Var',
  json: 'JSON',
  less: 'Less',
  scss: 'SCSS',
  swift: 'Swift',
} as const;

export type TokenNameDisplayFormat = keyof typeof tokenNameDisplayFormats;
